<template>
  <div class="hello-ezuikit-js">
    <div v-if="isShow" :id="ids" :style="{width: attributes.width + 'px', height: attributes.height + 'px'}"></div>
    <div v-else :style="{width: attributes.width + 'px', height: attributes.height + 'px'}" class="nos">
      <img src="../assets/images/dataScreen/huai.png" alt="" style="width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto;">
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";

export default {
  name: "HelloWorld",
  data(){
    return{
      ids: this.msg,
      flvs: this.flv,
      attributes: this.attribute,
      isShow: true
    }
  },
  props: {
    msg: String,
    flv: String,
    attribute: Object
  },
  mounted() {
    setInterval(() => {
      window.reload();
    },120000)

    this.showSxt();
    // console.log(this.attribute)
    // console.group("mounted 组件挂载完毕状态===============》");
    
    
    // console.log("player",player);
    // setTimeout(()=>{
    //   player.stop(); // 方法调用示例，10秒后关闭视频
    // },10000)
  },
  methods: {
    showSxt(){
      this.$nextTick(() => {
        var player =  new EZUIKit.EZUIKitPlayer({
            
          autoplay: true,
          id: this.ids,
          accessToken: 'at.8yrhy7xj34x6fy207zch0v9j17mcyknu-1e8rus8h7s-1otl1vm-phxwxiucx',
          url: this.flvs,
          template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          //plugin: ['talk'],                       // 加载插件，talk-对讲
          // 视频下方底部控件
          // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
            audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: data => console.log("开启声音回调", data),
          // closeSoundCallBack: data => console.log("关闭声音回调", data),
          // startSaveCallBack: data => console.log("开始录像回调", data),
          // stopSaveCallBack: data => console.log("录像回调", data),
          // capturePictureCallBack: data => console.log("截图成功回调", data),
          // fullScreenCallBack: data => console.log("全屏回调", data),
          // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
          width: this.attributes.width,
          height: this.attributes.height,
          handleError: (err) => {
            console.log(this.$parent)
            this.isShow = false;
          }
        });
        // this.qa.getAccessToken({}).then(res => {
        //   console.log(this.flvs)
        //   var player =  new EZUIKit.EZUIKitPlayer({
            
        //     autoplay: true,
        //     id: this.ids,
        //     accessToken: res.data.accessToken,
        //     url: this.flvs,
        //     template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        //     // 视频上方头部控件
        //     //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //     //plugin: ['talk'],                       // 加载插件，talk-对讲
        //     // 视频下方底部控件
        //     // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        //      audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        //     // openSoundCallBack: data => console.log("开启声音回调", data),
        //     // closeSoundCallBack: data => console.log("关闭声音回调", data),
        //     // startSaveCallBack: data => console.log("开始录像回调", data),
        //     // stopSaveCallBack: data => console.log("录像回调", data),
        //     // capturePictureCallBack: data => console.log("截图成功回调", data),
        //     // fullScreenCallBack: data => console.log("全屏回调", data),
        //     // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        //     width: this.attributes.width,
        //     height: this.attributes.height,
        //     handleError: (err) => {
        //       console.log(this.$parent)
        //       this.isShow = false;
        //     }
        //   });

        //   console.log(player);
        // })
      
    })
    }
  }
};
</script>

<style scoped>
/* .nos {
  background: url(../assets/images/dataScreen/huai.png) no-repeat center center;
  background-size: 100% 100%;
} */
</style>
