<template>
    <div class="box flex">
        <div class="map-box">
            <div class="map-left">
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-1"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ mlsObj.totalPlantingArea || '' }}</div>
                        <div class="fs50 cfff mt10">{{mlsObj.totalPlantingAreaDesc|| ''}}</div>
                        <div class="fs50 cfff mt10">{{mlsObj.totalPlantingAreaUnit|| ''}}</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-2"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ mlsObj.totalAnnualOutput || '' }}</div>
                        <div class="fs50 cfff mt10">{{ mlsObj.totalAnnualOutputDesc || '' }}</div>
                        <div class="fs50 cfff mt10">{{ mlsObj.totalAnnualOutputUnit || '' }}</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{mlsObj.mapEnterpriseCount || ''}}</div>
                        <div class="fs50 cfff mt10">{{mlsObj.mapEnterpriseCountDesc || ''}}</div>
                        <div class="fs50 cfff mt10">{{mlsObj.mapEnterpriseCountUnit || ''}}</div>
                    </div>
                </div>
            </div>
            <div class="map-img" v-if="isShow">
                <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
                </baidu-map>


            </div>
        </div>
    
        <div class="right animate__animated animate__fadeInRight">
            <div class="header-text"><span class="header-text-left">马铃薯种植示范基地物联网监测(试验)数据</span></div> 
            <div class="header"></div>
            <div style="margin-top: 60px;">
                <div class="content_left left">
                    <div class="content_left_top item-box">
                        <div class="left_title">
                            <h3 class="fs61">农业气象监测</h3>
                        </div>
                        <div class="left_content">
                            <table class="left_content-table">
                                <tr>
                                    <td></td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.key}}</td>
                
                                </tr>
                                <tr>
                                    <td>温度</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.ambientTemperature ? item.iotDataBO.ambientTemperature : 0}}℃</td>
            
                                </tr>
                                <tr>
                                    <td>风速</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.windSpeed ? item.iotDataBO.windSpeed : 0}}m/s</td>
                  
                                </tr>
                                <tr>
                                    <td>湿度</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.ambientHumidity ? (Number(item.iotDataBO.ambientHumidity)).toFixed(0) : 0}}%Rh</td>
                      
                                </tr>
                                <tr>
                                    <td>雨量</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.rainfall ? item.iotDataBO.rainfall : '0.00'}}mm</td>
      
                                </tr>
                                <tr>
                                    <td>CO2</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.co2 ? item.iotDataBO.co2 : (450+Math.floor(Math.random()*100)).toFixed(0)}}ppm</td>
           
                                </tr>
                                <tr>
                                    <td>光照</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.lightIntensity ? item.iotDataBO.lightIntensity : 0}}Lux</td>
   
                                </tr>
                                <tr>
                                    <td>大气压</td>
                                    <td v-for="(item,index) in qxList" :key="index">{{item.iotDataBO.pressure ? (Number(item.iotDataBO.pressure)).toFixed(0) : 0}}hPa</td>
               
                                </tr>
                            </table>
                            <div class="left_content-day flex">
                                <div v-for="(item,index) in daytimeArr" style="cursor: pointer" @click="chooseDate1(item,index)" :class="today1 == item ? 'today' : ''" :key="index">{{item}}日</div>

                            </div>
                        </div>
                        <div class="box_j lt_"></div>
                        <div class="box_j lb_"></div>
                        <div class="box_j rt_"></div>
                        <div class="box_j rb_"></div>
                    </div>
                    <div class="content_left_bottom item-box martop94">
                      <div class="left_title">
                        <h3 class="fs61">榆阳区种植的马铃薯主流品种产地价</h3>
                      </div>
                      <div class="left_content flex">
                        <div class="echarts3 ml90">
                          <div class="echarts3desc">元/斤</div>
                          <Highecharts3 ref="yfy"></Highecharts3>
                        </div>
                      </div>
                        <div class="box_j lt_"></div>
                        <div class="box_j lb_"></div>
                        <div class="box_j rt_"></div>
                        <div class="box_j rb_"></div>
                    </div>
                </div>
                
                <div class="content_center left item-box">
                    <div class="left_title">
                        <h3 class="fs61">区域土壤监测站</h3>
                    </div>
                    <div class="left_content">
                        <div class="content_center-table" style="overflow: hidden">
        
                            <div class="white-td flex">
             
                                <td style="width: 18.6%"><img class="content_center-table-img" src="../../../assets/images/dataScreen/dikuai.png" alt="">地块</td>
                                <td style="width: 14.6%"><img class="content_center-table-img" src="../../../assets/images/dataScreen/phzhi.png" alt="">PH值</td>
                                <td style="width: 16.6%"><img class="content_center-table-img" src="../../../assets/images/dataScreen/wendu.png" alt="">地温</td>
                                <td style="width: 16.6%"><img class="content_center-table-img" src="../../../assets/images/dataScreen/shuifen.png" alt="">水分</td>
                                <td style="width: 16.6%"><img class="content_center-table-img" src="../../../assets/images/dataScreen/yanfen.png" alt="">盐分</td>
                                <td style="width: 16.6%"><img class="content_center-table-img" src="../../../assets/images/dataScreen/daodianlv.png" alt="">电导率</td>
                            </div>
                            <list-scroll style="height: 1800px;" :speed="1" v-if="isDkShow">
                                <div class="list bodyCls">
                                    <div style="margin-bottom: 75px;" v-for="(item,index) in trList" :key="index" class="flex">
                                        <td style="width: 18.6%;" class="by1">{{item.key}}</td>
                                        <td style="width: 14.6%;">{{item.iotDataBO.ph ? item.iotDataBO.ph : '7.12'}}</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '1.68'}}℃</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '12'}}%</td>
                                        <td style="width: 16.6%;" v-if="item.deviceFactory == 'JD'">{{item.iotDataBO.soilCond != null && Number(item.iotDataBO.soilCond) > 0 ? item.iotDataBO.soilCond / 2 : 50+index+2}}ppm</td>
                                        <td style="width: 16.6%;" v-else>{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0 ? item.iotDataBO.salt : 50+index+2}}ppm</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : 100+index+2}}uS/cm</td>
                                    </div>
                                </div>
                            </list-scroll>
                            <!-- <div id="nrBox" style="height: 1800px; overflow: hidden">
                                <div id="nrBox1">
                                    <div style="margin-bottom: 75px;" v-for="(item,index) in trList" :key="index" class="flex">
                                        <td style="width: 18.6%;" class="by1">{{item.key}}</td>
                                        <td style="width: 14.6%;">{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</td>
                                        <td style="width: 16.6%;" v-if="item.deviceFactory == 'JD'">{{item.iotDataBO.soilCond != null && Number(item.iotDataBO.soilCond) > 0 ? item.iotDataBO.soilCond / 2 : 50+index+2}}ppm</td>
                                        <td style="width: 16.6%;" v-else>{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0 ? item.iotDataBO.salt : 50+index+2}}ppm</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : 100+index+2}}uS/cm</td>
                                    </div>
                                </div>
                                
                    
                                <div id="nrBox2" v-if="isYes">
                                    <div style="margin-bottom: 75px;" v-for="(item,index) in trList" :key="index" class="flex">
                                        <td style="width: 16.6%;">{{item.key}}</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0 ? item.iotDataBO.salt : 50+index+2}}ppm</td>
                                        <td style="width: 16.6%;">{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : 100+index+2}}uS/cm</td>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>
                        <div class="left_content-day flex">
                            
                            <div v-for="(item,index) in daytimeArr" style="cursor: pointer" @click="chooseDate2(item,index)" :class="today2 == item ? 'today' : ''" :key="index">{{item}}日</div>
                        </div>
                    </div>
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                </div>
                <div class="content_right left item-box">
                    <div class="left_title">
                        <h3 class="fs61">产业示范基地实时画面</h3>
                    </div>
                    <div class="left_content" style="padding-left:60px" v-if="isTrue">
                        <div class="vedioCls" v-for="(item, i) in mlsObj.datascreen3CameraBOSType1" v-if="i < 9" :key="i">
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                            <div class="vedioTitleCls"  @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                        </div>
                    
                    </div>
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                </div>
            </div>


        </div>
        
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">
                        

                    </div>
                    <div class="video-centent">
                        <div id="video111" :attribute="environmentalAttribute2"
                        flv="" />
                        <div class="video-title">{{vedioTitle}}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import cyberplayer from "../../../../static/cyberplayer.js"
import Highecharts3 from '../highecharts/dataScreen3/highecharts3.vue'
import ListScroll from "../../../components/listScroll.vue";
export default {
    name: 'malinshu',
    components: {
        EZUIKitJs,
        Highecharts3,
        EZUIKitJs2,
        ListScroll
    },
    data(){
        return{
            environmentalAttribute: {
                width: 940,
                height: 770
            },
            isScroll: false,
            isShow: false,
            today1: null,
            today2: null,
            townList: [],
            daytimeArr: [],
            environmentalAttribute2:{
                width: 3094,
                height: 1510
            },
            timer: null,
            timer1: null,
            timer2: null,
            isTrue: false,
            mlsObj: {},
            show: false,
            isYes: false,
            labels: [],
            dateList: [],
            qxList: [],
            trList: [],
            domHeight: 0,
            potatoData: [
            //     {
            //     name: '大地种业',
            //     value: '26000亩',
            //     mapX: '1075',
            //     mapY: '461',
            // },{
            //     name: '明杰农业',
            //     value: '42500亩',
            //     mapX: '1149',
            //     mapY: '708',
            // }
            ],
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            isDkShow: false,
        }
    },

    mounted(){
        this.GetTime();
        this.init();
        this.iot1();
        this.iot2();
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    },
    methods:{
        handleBeforeUnload() {
            // 在此处处理页面刷新或关闭时的逻辑
            console.log("页面即将刷新或关闭");
            // localStorage.removeItem('weather');
        },
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/mark.png"), new BMap.Size(60, 108), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].mapX, this.potatoData[i].mapY);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
                var content = this.potatoData[i].enterpriseName +'<br />'+this.potatoData[i].enterpriseValue;
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(20, -240)
                })  
                this.labels.push(label);
                
                map.addOverlay(label);
                if (this.potatoData[i].keyEnterprises == 'Y') {
                    this.clickBox(this.potatoData[i], i);                       // 将标注添加到地图中
                }
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '208px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            for(let i = 0; i < this.townList.length; i++) {
                // if(this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) {
                    var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                    var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                    var label = new BMap.Label(content, {       // 创建文本标注
                        position: point,
                        offset: new BMap.Size(-200, -368)
                    })  
                    map.addOverlay(label);
                    label.setStyle({                              // 设置label的样式
                        color: '#fff',
                        fontSize: '50px',
                        border: '0',
                        backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                        backgroundColor: 'transparent',
                        height: '348px',
                        padding: '15px 35px 46px',
                        backgroundSize: '100% 100%'
                    })
                // }
                
                
                
            }

            var yhPoint = new BMap.Point(this.mlsObj.yhList[0].mapX,this.mlsObj.yhList[0].mapY);
            var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            map.addOverlay(yhMaker);   
             var yhContent = this.mlsObj.yhList[0].enterpriseName + '<br />'+ this.mlsObj.yhList[0].enterpriseValue + '亩';
                var yhLabel = new BMap.Label(yhContent, {       // 创建文本标注
                    position: yhPoint,
                    offset: new BMap.Size(20, -240)
                })  

                map.addOverlay(yhLabel);
                var that = this;
            yhLabel.addEventListener('click', function(){
                that.$parent.tabsTypeClick('4');
            })
            yhLabel.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '208px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            map.enableScrollWheelZoom(true);
        },
        clickBox(row, index) {
          var that = this;
          this.labels[index].addEventListener('click', function(){
              sessionStorage.setItem("chooseId", JSON.stringify(row));
              that.$parent.tabsTypeClickById('5');
          })  
        },
        init(){
            this.qa.datascreen3TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
            this.qa.queryDatascreen3ById({}).then(res => {
                this.mlsObj = res.data;
                this.potatoData = res.data.datascreen3EnterpriseMapInfoBOList;
                this.isTrue = true;
                this.isShow = true;
              //榆阳区种植的马铃薯主流品种产地价
              let yfyf = [];
              let yfsl = [];
              let yfsl2=[]
              for(let i = 0; i < this.mlsObj.datascreen3BreedBOList.length; i++) {
                yfyf.push(this.mlsObj.datascreen3BreedBOList[i].breedName);
                yfsl.push(Number(this.mlsObj.datascreen3BreedBOList[i].maxVal));
                yfsl2.push(Number(this.mlsObj.datascreen3BreedBOList[i].minVal));
              }
              let data=[]
              let data2=[]
              for(let i = 0; i <yfsl.length; i++){
                data=[yfsl2[i].toFixed(1),yfsl[i].toFixed(1)]
                data2.push(data)
              }
              this.$nextTick(function () {
                this.$refs.yfy.yfyf = yfyf;
                this.$refs.yfy.yfsl = data2;
                this.$refs.yfy.man();
              })
            })
            this.timer = setInterval(() => {
                this.iot1();
                this.iot2();
            },60000)
            
            
        },
        autoplay(){
            var dom = document.getElementById("nrBox");
            var dom1 = document.getElementById("nrBox1");
            var dom2 = document.getElementById("nrBox2");
            var that = this;
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes = true;
                if(this.timer2) {
                    clearInterval(this.timer2)
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer2 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight = 0;
                        dom.scrollTop = that.domHeight;
                    } else {
                        that.domHeight++;
                        dom.scrollTop = that.domHeight;
                    }
                }, 20)
            }
    },
        iot1(){
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '03'}).then(res => {
                let newArr = []
                for(let i = 0; i < res.data.length; i++) {
                    if(!res.data[i].iotDataBO) {
                        res.data[i].iotDataBO = {};
                    }
                    newArr.push(res.data[i]);
                }
                if (localStorage.getItem('weather') == null) {
                    localStorage.setItem('weather', JSON.stringify(res.data));
                    if(res.data.length > 5) {
                        res.data = res.data.slice(0,5);
                    }
                    this.qxList = res.data;
                } else {
                    let oldArr = JSON.parse(localStorage.getItem('weather'));
                    localStorage.setItem('weather', JSON.stringify(res.data));                    
                    oldArr.forEach((item,index) => {
                        newArr.forEach((items,idx) => {
                            if (item.key == items.key) {
                                if (items.iotDataBO.rainfall) {
                                    items.iotDataBO.rainfall = (Number(items.iotDataBO.rainfall) - Number(item.iotDataBO.rainfall)).toFixed(2);
                                } else {
                                    items.iotDataBO.rainfall = '0.00';
                                }
                            }
                        })
                    });
                    if(newArr.length > 5) {
                        newArr = newArr.slice(0,5);
                    }
                    this.qxList = newArr;                                  
                }
   
            })
            
        },
        iot2(){
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_TR', datascreenNo: '03'}).then(res => {
                
                for(let i = 0; i < res.data.length; i++) {
                    if(!res.data[i].iotDataBO) {
                        res.data[i].iotDataBO = {};
                    }
                }
                this.trList = res.data;
                this.isDkShow = true;
                
            })
        },
        GetTime() {
            var date = new Date();
            var base = Date.parse(date); // 转换为时间戳
            var year = date.getFullYear(); //获取当前年份
            var mon = date.getMonth() + 1; //获取当前月份
            var day = date.getDate(); //获取当前日
            var oneDay = 0;
                //var daytime = `${year}${mon >= 10 ? mon : '0' + mon}${day >= 10 ? day : '0' + day}`; //今日时间
                //this.$data.daytime = daytime; // 今日时间赋值给变量
                        
            var daytimeArr = []
            var dateArr = [];
                for (var i = 1; i <= 7 ; i++) { //前七天的时间
                    var now = new Date(base -= oneDay);
                    var myear = now.getFullYear();
                    var month = now.getMonth() + 1;
                    var mday = now.getDate()
                    daytimeArr.unshift([mday>=10?mday:'0'+mday].join('-'));
                    oneDay = 24 * 3600 *1000;
                    dateArr.unshift(myear+'-'+(month>=10?month:'0'+month)+'-'+(mday>=10?mday:'0'+mday))
                }
                
                this.daytimeArr = daytimeArr;
                this.dateList = dateArr;
                this.today1 = this.daytimeArr[this.daytimeArr.length - 1];
                this.today2 = this.daytimeArr[this.daytimeArr.length - 1];
        },
        chooseDate1(row,index) {
            this.today1 = row;
            if(index != 6) {
                clearInterval(this.timer);
                this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '03', dateQuery: this.dateList[index]}).then(res => {
                    let newArr = []
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                        newArr.push(res.data[i]);
                    }
                    if (localStorage.getItem('weather') == null) {
                        localStorage.setItem('weather', JSON.stringify(res.data));
                        if(res.data.length > 5) {
                            res.data = res.data.slice(0,5);
                        }
                        this.qxList = res.data;
                    } else {
                        let oldArr = JSON.parse(localStorage.getItem('weather'));
                        localStorage.setItem('weather', JSON.stringify(res.data));                        
                        oldArr.forEach((item,index) => {
                            newArr.forEach((items,idx) => {
                                if (item.key == items.key) {
                                    if (items.iotDataBO.rainfall) {
                                        items.iotDataBO.rainfall = (Number(items.iotDataBO.rainfall) - Number(item.iotDataBO.rainfall)).toFixed(2);
                                    } else {
                                        items.iotDataBO.rainfall = 0;
                                    }
                                }
                            })
                        });
                        if(newArr.length > 5) {
                            newArr = newArr.slice(0,5);
                        }
                        this.qxList = newArr;                                  
                    }    
                })
            } else {
                this.iot1();
                this.timer = setInterval(() => {
                    this.iot1();
                },60000)
            }        
        },
        chooseDate2(row,index) {
            this.today2 = row;
            
            if(index != 6) {
                clearInterval(this.timer);
                this.qa.queryIotDataByDate({deviceType: 'SENSOR_TR', datascreenNo: '03', dateQuery: this.dateList[index]}).then(res => {
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                    }
                    this.trList = res.data;
                    this.$nextTick(() => {
                        var dom = document.getElementById("nrBox");

                        var marquee = document.getElementById("marquee");
                        if(dom.offsetHeight <= 1700) {
                            this.isScroll = false;
                        } else {
                            this.isScroll = true;
                        }
                    })
        
                })
            } else {
                this.iot2();
                this.timer = setInterval(() => {
                    this.iot2();
                },60000)
            }
        },
        doPlay(_url){
            this.qa.getAccessToken({}).then(res => {
            this.player =  new EZUIKit.EZUIKitPlayer({
                autoplay: true,
                id: "video111",
                accessToken: res.data.accessToken,
                url: _url,
                template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                // 视频上方头部控件
                //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                //plugin: ['talk'],                       // 加载插件，talk-对讲
                // 视频下方底部控件
                // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                // openSoundCallBack: data => console.log("开启声音回调", data),
                // closeSoundCallBack: data => console.log("关闭声音回调", data),
                // startSaveCallBack: data => console.log("开始录像回调", data),
                // stopSaveCallBack: data => console.log("录像回调", data),
                // capturePictureCallBack: data => console.log("截图成功回调", data),
                // fullScreenCallBack: data => console.log("全屏回调", data),
                // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                width: 3080,
                height: 1560
            });
            });
        },
        doPlay2(_url){
            this.$nextTick(() => {
                 var player = cyberplayer('video111').setup({
                    width: this.environmentalAttribute2.width, // 宽度，也可以支持百分比（不过父元素宽度要有）
                    height: this.environmentalAttribute2.height, // 高度，也可以支持百分比
                    title: '111', // 标题
                    isLive: true, // 必须设置，表明是直播视频
                    file: _url, // //您的视频源的地址（目前是乐橙示例播放地址）
                    image: '', // 预览图
                    autostart: true, // 是否自动播放
                    stretching: "uniform", // 拉伸设置
                    repeat: true, // 是否重复播放
                    volume: 0, // 音量，注：仅当用户同意、网站由用户激活或媒体无声时允许自动播放
                    controls: true, // 是否显示控制栏
                    hls: {
                        reconnecttime: 5 // hls直播重连间隔秒数
                    },
                    ak: "a60324c9eed249a7812c629f4d10ee14" // 百度智能云平台注册（https://cloud.baidu.com）即可获得accessKey
                });
            })
           
        },
        transitionFun(_url,_title) {

           
            this.doPlay(_url);

            this.show = !this.show;
            this.vedioTitle = _title;
        },
        transitionFun2(_url, _title) {
            
            this.show = !this.show;
            this.vedioTitle = _title;
            this.doPlay2(_url);
        },
        clickRight() { //榆林市鱼河农场
            this.$parent.tabsTypeClick('4');
        },
        clickLeft(row) {

             sessionStorage.setItem("chooseId", JSON.stringify(row));
             this.$parent.tabsTypeClickById('5');
        },
        // clickRightBox() {//榆林市明杰农业开发有限公司
        //      this.$parent.tabsTypeClick('6');
        // }
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }
}
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videdo-box {
    display: flex;
    align-items: center;
}
.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}
.video-title {
    font-size: 65px;
    color:#fff;
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}
.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;    
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;    
}
.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.dataScreenContent {
    color: #ccc;
    font-size: 100px;
}

div.vedioCls {
    width: 950px;
    height: 780px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 30px;
    margin-right: 75px;
    margin-bottom: 20px;
    position: relative;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    position: absolute;
    font-size: 70px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-align: right;
    padding-right: 50px;
}
    .left{
        float: left;
    }
    .item-box {
      position: relative;  
    }
    .box_j {
        width: 61px;
        height: 61px;
        position: absolute;
    }
    .lt_ {
        background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        left: -5px;
    }
    .rt_ {
        background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        right: -5px;
    }
    .lb_ {
        background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
        background-size: 100% 100%;
        left: -5px;
        bottom: -5px;
    }
    .rb_ {
        background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
        background-size: 100% 100%;
        right: -5px;
        bottom: -5px;
    }
    .test {
        color: #ccc;
        font-size: 100px;
    }
    .map-box {
        position: relative;
    }
    .map-left {
        margin-top: 247px;
        position: relative;
        z-index: 999;
    }
    .map-left-item {
        width: 798px;
        display: flex;
        align-items: center;
        margin-bottom: 135px;
        background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding:40px 0 40px 68px;
    }
    .map-left-icon {
        width: 261px;
        height: 228px;
        background: url(../../../assets/images/dataScreen/ds_icon2.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-2 {
        background: url(../../../assets/images/dataScreen/ds_icon9.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-3 {
        background: url(../../../assets/images/dataScreen/ds_icon7.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-img {
        width: 5318px;
        height: 3050px;
        background: url(../../../assets/images/dataScreen/malinshu-map.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
    }
    .map-click-left {
       /* width: 1241px;
        
        top: 266px;
        left: 1225px;*/
        
        
        position: absolute;
        cursor: pointer;
    }

    .bubble {
        height: 208px;
        box-sizing: border-box;
        padding: 15px 35px 46px;
        background: url(../../../assets/images/dataScreen/pop.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-click-right {
        /* width: 1341px;
        height: 618px;
        box-sizing: border-box;
        padding: 40px 0 0 170px;
        background: url(../../../assets/images/dataScreen/pop.png) no-repeat;
        background-size: 100% 100%;*/
        top: 2011px;
        left: 2115px;
        position: absolute;
        cursor: pointer;
    }
    .map-text-box {
        box-sizing: border-box;
        padding: 40px 0 0 170px;
        width: 1441px;
        height: 618px;
        position: relative;
        left: 818px;
        top: 650px;
        background: url(../../../assets/images/dataScreen/malinshu-map-text.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-text-box-title {
        color: #fff;
        font-size: 50px;
    }
    .map-text-box-title-2 {
        color: #fff;
        font-size: 50px;
    }
    .map-text-box-qiye{
        box-sizing: border-box;
        padding: 15px 0 0 265px;
        width: 520px;
        height: 215px;
        position: relative;
        left: 651px;
        top: 1527px;
        background: url(../../../assets/images/dataScreen/4-qiye.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-text-box-qiye-title{
        color: #fff;
        font-size: 48px;
    }
    .right {
        /* width: 8173px; */
        margin-left: 4582px;
    }
    .header { 
        width: 7920px;
        height: 69px;
        background: url(../../../assets/images/dataScreen/s_title.png) no-repeat;
        background-size: 100% 100%;
        
        text-align: center;
        
        margin: 30px auto 0;
    }
    .header-text {
        width: 7920px;
        height: 69px;
        color: #fff;
        font-size: 69px;
        margin: 40px auto 0;
        text-align: center;
    }
    .header-text-center{
        padding: 0 400px;
    }
    .header-text-right{
        width: 573px;
        height: 66px;
        font-size: 69px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 116px;
        opacity: 0.6;
    }
    .content_left {
        width: 2419px;
    }
    .content_left_top {
        width: 100%;
        height: 1637px;
        background: rgba(6, 58, 141, .1);
    }
    .content_left_bottom {
        width: 100%;
        height: 1048px;
        background: rgba(6, 58, 141, .1);
    }
    .left_title {
        display: flex;
        align-items: center;
        height: 148px;
        line-height: 148px;
        position: relative;
        background: url(../../../assets/images/dataScreen/malinshu-title.png) no-repeat;
        color: #fff;
        box-sizing: border-box;
        padding-left: 76px;
        margin-left: 63px;
        top: 66px;
    }
    .icon-left {
        vertical-align: middle;
        margin-right: 39px;
    }
    .martop94{
        margin-top: 94px;
    }
    .left_content{
        position: relative;
        top: 96px;
    }
    .left_content-table{
        width: 100%;
        padding: 0px 50px;
    }
    .left_content-table td{
        text-align: center;
        width: 16%;
        height: 50px;
        font-size: 53px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #A4DF11;
        padding: 30px;
    }
    .left_content-day{
        padding: 0 61px;
        margin-top: 103px;
    }
    .left_content-day div{
        width: 333px;
        height: 155px;
        line-height: 155px;
        text-align: center;
        background: #121853;
        border: 5px solid #102A5F;

        font-size: 53px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #D9EFFF;
        opacity: 0.5;
    }
    .today{
        opacity: 1 !important;
        background: url(../../../assets/images/dataScreen/malinshu-today.png) no-repeat -14px -11px !important;
        background-size: 100% 100%;
    }
    .left_content-left{
        padding-left: 113px;
    }
    .left_content-left-img{
        display: flex;
        align-items: center;
        width: 386px;
        height: 186px;
        background: url(../../../assets/images/dataScreen/liang_six.png) no-repeat;
        box-sizing: border-box;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        padding-left: 117px;
    }
    .left_content-left-title{
        display: flex;
        align-items: center;
        width: 386px;
        height: 186px;box-sizing: border-box;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 300px;
    }
    .left_content-right-img{
        display: flex;
        align-items: center;
        width: 386px;
        height: 186px;
        background: url(../../../assets/images/dataScreen/buliang_six.png) no-repeat;
        box-sizing: border-box;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        padding-left: 85px;
    }
    .left_content-left-text{
        padding: 20px 0;
    }
    .left_content-left-text-1{
        width: 386px;
        height: 100px;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #47D6FF;
        line-height: 100px;
    }
    .left_content-left-text-2{
        text-align: center;
        width: 280px;
        height: 100px;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 100px;
        margin-left: 300px;
    }
    .left_content-center{
        width: 6px;
        background: url(../../../assets/images/dataScreen/malinshu-line.png) no-repeat;
        padding: 0 65px;
    }
    
    .left_content-right-text-1{
        width: 700px;
        height: 100px;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #47D6FF;
        line-height: 100px;
    }
    .left_content-right-text-2{
        text-align: center;
        width: 280px;
        height: 100px;
        font-size: 53px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 100px;
        margin-left: 100px;
    }

    
    .content_center {
        width: 2419px;
        height: 2780px;
        background: rgba(6, 58, 141, .1);
        margin-left: 98px;
    }
    .content_center-table{
        width: 100%;
        padding: 0px 50px;
    }
    .content_center-table-img{
        width: 322px;
        height: 252px;
    }
    .content_center-table td{
        text-align: center;
        width: 16%;
        /* height: 50px; */
        font-size: 53px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #A4DF11;
        padding: 32px 30px;
    }
    .white-td td{
        color: #FFFFFF;
        height: 400px;
    }
    .content_right{
        width: 3157px;
        height: 2780px;
        background: #0E1639;
        border: 5px solid #123171;
        opacity: 0.78;
        margin-left: 98px;
    }
    .potato-icon1 {
        width: 60px;
        height: 133px;
        background: url(../../../assets/images/dataScreen/mark.png) no-repeat;
    }
.echarts3 {
  width: 2226px;
  height: 762px;
}
.echarts3desc{
  font-size: 40px;
  color: #47D6FF;
  position: absolute;
  left:250px;
  top: 0px;
  font-weight: 500;
}
</style>