<template>
  <div class="echarts-box">
    <div id="container7"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  data(){
    return {
        xData: [],
        yData: [],
        year1: '',
        isInit: false,
    }
  },
  props: {
    msg: String
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
        if (this.isInit) {
            echarts.dispose(document.getElementById('container7'));            
        }
        var chartDom = document.getElementById('container7');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.xData;
        var yStandard1 = this.yData; //标准
        var lineName1 = this.year1;
        option = {
            grid: {
                left: '6%',
                top: '15%',
                bottom: '10%'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    // 坐标轴指示器配置项。
                    type: "line", // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
                    axis: "auto", // 指示器的坐标轴。
                    snap: true, // 坐标轴指示器是否自动吸附到点上
                },
                borderColor: "rgba(0,0,0,0.58);",//设置自定义边框颜色
                borderWidth: 1,//设置自定义边框宽度
                backgroundColor: "rgba(0,0,0,0.58);",//设置自定义背景
                confine: true,//是否将tooltip框限制在图表的区域内，默认为false
                extraCssText: "box-shadow: 0 0 5px rgba(181, 245, 236, 0.5);padding:5px 15px",  //额外附加到浮层的css样式，此处为为浮层添加阴影及padding
                formatter: function (params) {
                    var data = params[0];
                    return `${data.name}<br/>${data.value}%`;
                },
                textStyle: {
                  color: '#FFF',
                  fontSize: 50
                },
                axisLabel: {
                    margin: 40,
                    textStyle: {
                        color: '#CCCCCC',
                        fontSize: 40
                    },
                },
            },
            xAxis: {
                type: 'category',
                show: true,
                position: 'bottom',
                splitNumber: 12,
                data: xData.length>0?xData:['0时', '1时', '2时', '3时', '4时', '5时', '6时', '7时', '8时', '9时', '10时', '11时', '12时', '13时', '14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时',],
                axisLabel: {
                    margin: 40,
                    textStyle: {
                        color: '#CCCCCC',
                        fontSize: 40
                    },
                },
                axisLine: {
                    show: true, 
                    lineStyle: {
                        color: '#ccc',
                        width: 2
                    }
                },
                // splitLine: {  //网格线
                //     show: true,  //是否显示
                //     lineStyle: {  //网格线样式
                //         color: '#CCCCCC',  //网格线颜色
                //         width: 1,  //网格线的加粗程度
                //         type: 'solid' //网格线类型
                //     }
                // },
            },
            yAxis: {
                type: 'value',
                show: true,
                position: 'bottom',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#ccc',
                        width: 1
                    }
                },
                axisLabel: {
                    margin: 40,
                    textStyle: {
                        color: '#CCCCCC',
                        fontSize: 40
                    },
                },
                splitLine: {
                    show: true,  //是否显示
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 2
                    }
                },
            },
            series: [
                {
                    name: '温度',
                    type: 'line',
                    symbol: "circle",
                    symbolSize: 15,
                    data: yStandard1,
                    markPoint: {
                        data: [
                            { type: 'min', name: '极小值' },
                            { type: 'max', name: '极大值' }
                        ],
                        axisLabel: {
                            textStyle: {
                                color: '#CCCCCC',
                                fontSize: 40
                            },
                        },
                        label: {
                            formatter: '{b}\n\n\n{c}%',
                            color: '#FFFFFF',
                            fontSize: 40
                        },
                        itemStyle: {
                              fontSize: 45,
                        }
                    },
                    markLine: {
                        data: [
                            { name: '', type: 'average' }
                        ],
                        label: {
                            formatter: '{b}',
                            fontSize: 40
                        },
                        lineStyle: {
                            type: 'dashed',
                            color: '#ffa500',
                            width: 6
                        }
                    },
                    itemStyle: {
                        color: '#fff',
                        borderColor: 'rgba(0,0,0,0.39)',
                        borderWidth: 30
                    },
                    lineStyle: {
                        color: '#00c8ff',
                        width: 6
                    }
                }
            ],
            backgroundColor: '#0D1533'
        };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
    .charts-box, #container7 {
        width: 100%;
        height: 100%;
    }
</style>