<template>
  <div class="box flex">
    <div class="map-box">
      <div class="map-left">
        <div :class="['map-left-item', 'animate__animated', timeNum >= 0? 'animate__bounceInDown': '']">
          <div class="map-left-icon map-left-icon-1"></div>
          <div class="map-left-text pb33">
            <div class="c23D1FF fs101">{{smallObj.reserved1 || 0}}</div>
            <div class="fs50 cfff mt10">年饲养量（头）</div>
          </div>
        </div>
        <div :class="['map-left-item', 'animate__animated', timeNum > 0?  'animate__bounceInDown': '']">
          <div class="map-left-icon map-left-icon-2"></div>
          <div class="map-left-text pb33">
            <div class="c23D1FF fs101">{{smallObj.iotEquipmentCount || 0}}</div>
            <div class="fs50 cfff mt10">年存栏量（头）</div>
          </div>
        </div>
        <div :class="['map-left-item', 'animate__animated', timeNum > 0?  'animate__bounceInDown': '']">
          <div class="map-left-icon map-left-icon-3"></div>
          <div class="map-left-text pb33">
            <div class="c23D1FF fs101">{{smallObj.reserved3 || 0}}</div>
            <div class="fs50 cfff mt10">年出栏量（头）</div>
          </div>
        </div>
      </div>
      <div class="map-img">
        <baidu-map :center="center" v-if="isShow" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
        </baidu-map>
        <!-- <div v-for="(item, i) in smallObj.inventoryBOS" :style="{left: item.xaxis + 'px', top: item.yaxis + 'px'}" :key="i" class="map-click-left animate__animated animate__fadeInUp">
          <div class="bubble ml31 mb10">
            <div class="map-text-box-title">存栏数：{{item.basicCow}}</div>
            <div class="map-text-box-title ">基础母牛：{{item.inventoryNum}}</div>
          </div>
        </div> -->
        <!-- <div class="map-text-box animate__animated animate__fadeInUp">
          <img src="../../../assets/images/dataScreen/cow-map-icon.png" class="map-text-box-icon"/>
          <div class="map-text-box-title">{{smallObj.breedingBase}}</div>
          <div class="map-text-box-line"></div>
          <div class="map-text-box-title-2" >饲养量：{{smallObj.breedingNum}}只</div>
        </div> -->
      </div>
    </div>
    <div class="right">
      <div class="header-text animate__animated animate__zoomInDown">陕西大地金禾来肉牛数字养殖基地</div>
      <div class="header"></div>
      <div class="flex animate__animated animate__fadeInRight">
        <div class="content_left item-box">
          <div class="left_title">
            <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon1.png" alt="">
            <h3 class="fs61">实时基地画面</h3>
            <div class="title-line"></div>
          </div>
          <div class="shujuTableCls" v-if="isTrue">
            <div class="vedioCls" style="float: left;" v-for="(item, i) in smallObj.deviceEnterpriseRelationBOS" :key="i">
              <!-- <EZUIKitJs :msg="'video1'" :title="video1Title" :attribute="webcamAttribute"
                          :flv="smallObj.videoBOS[0].url" /> -->
                          <div style="width: 1200px; overflow: hidden">
                            <EZUIKitJs3 :msg="'video'+(i+1)" :attribute="webcamAttribute" :flv="item.deviceUrl" />
                            <!--<EZUIKitJs :msg="'video'+(i+1)" :attribute="webcamAttribute" :flv="item.deviceUrl" />-->
                              <!--<EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="webcamAttribute" :flv="item.deviceUrl"/>-->
                          </div>
                
              <div class="vedioTitleCls" @click="transitionFun(item.deviceUrl, item.installArea, item.deviceFactory)">{{item.installArea}}</div>
            </div>
            <!-- <div class="vedioCls" style="float: right">
              <EZUIKitJs :msg="'video2'" :title="video2Title" :attribute="webcamAttribute"
                          :flv="smallObj.videoBOS[1].url" />

              <div class="vedioTitleCls">{{smallObj.videoBOS[1].cowshed}}</div>
            </div>
            <div class="vedioCls" style="float: left;margin-top: 50px">
              <EZUIKitJs2 :msg="'video3'" :title="video3Title" :attribute="webcamAttribute"
                          :flv="smallObj.videoBOS[2].url" />
              <div class="vedioTitleCls">{{smallObj.videoBOS[2].cowshed}}</div>
            </div>
            <div class="vedioCls" style="float: right;margin-top: 50px">
              <EZUIKitJs2 :msg="'video4'" :title="video4Title" :attribute="webcamAttribute"
                          :flv="smallObj.videoBOS[3].url" />
              <div class="vedioTitleCls">{{smallObj.videoBOS[3].cowshed}}</div>
            </div> -->
          </div>
          <div class="box_j lt_"></div>
          <div class="box_j lb_"></div>
          <div class="box_j rt_"></div>
          <div class="box_j rb_"></div>
        </div>
        <div class="content_center item-box ml90">
          <div class="content_left_top item-box">
            <div class="left_title">
              <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon1.png" alt="">
              <h3 class="fs61">全场养殖数据<span class="fs46">(只)</span> </h3>
              <div class="title-line"></div>
            </div>
            <div class="flex f_jc_a pt57">
              <div class="ml100">
                <div class="fs66 ta_c mb39">青年牛</div>
                <div class="flex f_ai_c fs76">
                  <span style="color: #FED400;">{{smallObj.basicCow || 0}}</span>
                </div>
              </div>
              <div class="">
                <div class="fs66 ta_c mb39">成母牛</div>
                <div class="flex f_ai_c fs76">
                  <span style="color: #FED400;">{{smallObj.pregnantCow || 0}}</span>
                </div>
              </div>
              <div class="">
                <div class="fs66 ta_c mb39">犊牛</div>
                <div class="flex f_ai_c fs76">
                  <span style="color: #FED400;">{{smallObj.breedCattle || 0}}</span>
                </div>
              </div>
              <div class="mr100">
                <div class="fs66 ta_c mb39">育成牛</div>
                <div class="flex f_ai_c fs76">
                  <span style="color: #FED400;">{{smallObj.newbornCalf || 0}}</span>
                </div>
              </div>
              <div class="mr100">
                <div class="fs66 ta_c mb39">种公牛</div>
                <div class="flex f_ai_c fs76">
                  <span style="color: #FED400;">{{smallObj.breedingOxen || 0}}</span>
                </div>
              </div>
              <div class="mr100">
                <div class="fs66 ta_c mb39">育肥前期</div>
                <div class="flex f_ai_c fs76">
                  <span style="color: #FED400;">{{smallObj.fatteningEarly || 0}}</span>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="title-line-sub-box" style="width: 100%;">
                <div class="sheep-title fs54 ml95 mt58 mb55">
                  <div>牛场基本信息</div>
                  <div class="title-line-sub mt18"></div>
                </div>
                <div class="flex ml95">
                  <div class="flex" style="width: 40%" >
                    <div class="sheep-m"></div>
                    <div>
                      <div class="">
                        <span class="fs69">{{smallObj.floorage || 0}}</span>
                        <span class="fs46 cfff">平方米</span>
                      </div>
                      <div class="sheep-m-line mt12 mb12"></div>
                      <div class="fs54">总面积</div>
                    </div>
                  </div>
                  <div class="flex" style="width:40%">
                    <div class="sheep-m "></div>
                    <div>
                      <div class="">
                        <span class="fs69">{{smallObj.floorNum || 0}}</span>
                        <span class="fs46 cfff">栋</span>
                      </div>
                      <div class="sheep-m-line sheep-m-line-2 mt12 mb12"></div>
                      <div class="fs54">牛舍</div>
                    </div>
                  </div>
                  <div class="flex" style="width: 20%">
                    <div class="sheep-m"></div>
                    <div>
                      <div class="">
                        <span class="fs69">{{smallObj.reserved4 || 0}}</span>
                        <span class="fs46 cfff">个</span>
                      </div>
                      <div class="sheep-m-line sheep-m-line-3 mt12 mb12"></div>
                      <div class="fs54">物联网设备</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box_j lt_"></div>
            <div class="box_j lb_"></div>
            <div class="box_j rt_"></div>
            <div class="box_j rb_"></div>
          </div>
          <div class="content_left_center flex f_jc_b">
            <div class="content_left_center_left  item-box">
              <div class="left_title">
                <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon2.png" alt="">
                <div class="flex f_jc_b w100">
                  <h3 class="fs61">人员比例</h3>
                  <!--<div class="fs46 mr78" style="color: #FED400;">
                    <span>人效：</span>
                    <span>{{smallObj.reserved2}}</span>
                  </div>-->
                </div>
                <div class="title-line"></div>
              </div>
              <div class="ta_c mt30 echarts1">
                <div class="echarts-num">{{renCount}}</div>
                <Highecharts1 ref="renyuan"></Highecharts1>
              </div>
              <div class="box_j lt_"></div>
              <div class="box_j lb_"></div>
              <div class="box_j rt_"></div>
              <div class="box_j rb_"></div>
            </div>
            <div class="content_left_center_right  item-box">
              <div class="left_title">
                <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon2.png" alt="">
                <div class="flex f_jc_b w100">
                  <h3 class="fs61">
                    <span>牛饲养品种</span>
                  </h3>
                </div>
              </div>
              <div class="title-line-sub-box" style="padding: 0 97px">
                <!--<marquee
                    class="marquee-list"
                    direction="up"
                    behavior="scroll"
                    scrollamount="10">-->
                  <div class="flex fs60 mt5" style="color: #fff;line-height: 200px" v-for="(item,index) in smallObj.varietiesBOS" :key="index">
                    <div class="fs60" style="width: 500px;">{{item.varieties}}：</div>
                    <div style="color: #FED400;">{{item.varietiesCount}}</div>
                  </div>
                <!--</marquee>-->
              </div>
              <div class="box_j lt_"></div>
              <div class="box_j lb_"></div>
              <div class="box_j rt_"></div>
              <div class="box_j rb_"></div>
            </div>
          </div>
          <div class="content_left_bottom item-box" style="margin-top: 110px;">
            <div class="left_title">
              <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon4.png" alt="">
              <div class="flex f_jc_b w100">
                <h3 class="fs61">
                  <span>本场牛肉价格指数（元/斤）</span>
                </h3>
              </div>
              <div class="title-line"></div>
            </div>
            <div class="echarts8 ml90">
              <Highecharts8 ref="xsjg"></Highecharts8>
            </div>
            <div class="box_j lt_"></div>
            <div class="box_j lb_"></div>
            <div class="box_j rt_"></div>
            <div class="box_j rb_"></div>
          </div>
        </div>
        <div class="content_center item-box ml90">
          <div class="content_right item-box">
            <div class="left_title">
              <img class="icon-left" src="../../../assets/images/dataScreen/sheep/airec 智能推荐.png" alt="">
              <div class="flex f_jc_b w100">
                <h3 class="fs61">
                  <span>年存栏出栏量</span>
                </h3>
                <div class="flex fs61">
                  <div>单位/头</div>
                  <div class="flex ml100" style="align-items:center;">
                    <div class="fk_b mr30"></div>
                    <div>存栏</div>
                  </div>
                  <div class="flex ml100 pr100" style="align-items:center;">
                    <div class="fk_y mr30"></div>
                    <div>出栏</div>
                  </div>
                </div>
              </div>
              <div class="title-line"></div>
            </div>
            <div class="echarts3 mt90">
              <Highecharts3 ref="jcmy"></Highecharts3>
            </div>
            <div class="box_j lt_"></div>
            <div class="box_j rb_"></div>
          </div>
          <div class="content_right item-box mt47" style="height:798px;">
            <div class="left_title">
              <img class="icon-left" src="../../../assets/images/dataScreen/sheep/airec 智能推荐.png" alt="">
              <div class="flex f_jc_b w100">
                <h3 class="fs61">
                  <span>月存栏量</span>
                </h3>
                <div class="flex fs61 pr100">
                  <div>单位/头</div>
                </div>
              </div>
              <div class="title-line"></div>
            </div>
            <div class="echarts3 ml90 mt40">
              <Highecharts4 ref="yfy"></Highecharts4>
            </div>
            <div class="box_j lt_"></div>
            <div class="box_j rb_"></div>
          </div>
          <div class="content_right item-box" style="height:838px;margin-top:30px;">
            <div class="left_title">
              <img class="icon-left" src="../../../assets/images/dataScreen/sheep/airec 智能推荐.png" alt="">
              <div class="flex f_jc_b w100">
                <h3 class="fs61">
                  <span>月进出栏量</span>
                </h3>
                <div class="flex fs61">
                  <div>单位/头</div>
                  <div class="flex ml100" style="align-items:center;">
                    <div class="fk_b mr30" style="background:#5B34FF;"></div>
                    <div>进栏</div>
                  </div>
                  <div class="flex ml100 pr100" style="align-items:center;">
                    <div class="fk_y mr30" style="background:#B1FB87;"></div>
                    <div>出栏</div>
                  </div>
                </div>
              </div>
              <div class="title-line"></div>
            </div>
            <div class="echarts5 ml90">
              <Highecharts6 ref="xsgy"></Highecharts6>
            </div>
            <div class="box_j lt_"></div>
            <div class="box_j rb_"></div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">
                        

                    </div>
                    <div class="video-centent">
                        <div id="video111"></div>
                        <div class="video-title">{{vedioTitle}}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
  </div>
</template>

<script>

import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import Highecharts1 from '../highecharts/dataScreen2/highecharts1.vue'
import Highecharts2 from '../highecharts/dataScreen2/highecharts2.vue'
import Highecharts3 from '../highecharts/dataScreen2/highecharts3.vue'
import Highecharts4 from '../highecharts/dataScreen2/highecharts4.vue'
import Highecharts6 from '../highecharts/dataScreen2/highecharts6.vue'
import Highecharts8 from '../highecharts/dataScreen2/highecharts8.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import EZUIKitJs3 from '../../../components/EZUIKitJs3.vue'
import EZUIKit from "ezuikit-js";
import cyberplayer from "../../../../static/cyberplayer.js"
import 'animate.css';
export default {
  name: '',
  data(){
    return{
      renxiao: 0,
      smallObj: {},
      yujing: 0,
      lengthAll: 0,
      isTrue: false,
      timeNum: 0,
      renCount: 0,
      iot: {},
      isShow: false,
      show: false,
      webcamAttribute: {
        width: 1700,
        height: 1000
      },
      potatoData: [],
      center: {lng: 109.77446, lat: 38.27671},
      zoom: 12,
      video1Title: '1',
      video2Title: '2',
      video3Title: '3',
      video4Title: '4',
    }
  },
  components: {
    EZUIKitJs,
    Highecharts1,
    Highecharts2,
    Highecharts3,
    Highecharts4,
    Highecharts6,
    Highecharts8,
    EZUIKitJs2,
    EZUIKitJs3
  },
  mounted(){
    this.init();
    var timer = setInterval(() => {
      ++this.timeNum
      if(this.timeNum > 4) {
        clearInterval(timer)
      }
    },50)
  },
  methods:{
    handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/cow-map-icon.png"), new BMap.Size(86, 86), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].xaxis, this.potatoData[i].yaxis);   
                // var marker = new BMap.Marker(point, {icon: myIcon});   
                // map.addOverlay(marker);   
                // var content = '<div><>'this.potatoData[i].township +'<br />存栏数：'+this.potatoData[i].basicCow+'<br />出栏数：'+this.potatoData[i].inventoryNum;
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -146px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.potatoData[i].township + '</div><div>' +  '存栏数：'+this.potatoData[i].basicCow +'</div>'+'<div>出栏数：'+this.potatoData[i].inventoryNum+'</div></div>';

                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -308)
                })  
                // this.labels.push(label);
                
                map.addOverlay(label);
                // if (this.potatoData[i].keyEnterprises == 'Y') {
                //     this.clickBox(this.potatoData[i], i);                       // 将标注添加到地图中
                // }
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")',
                    backgroundColor: 'transparent',
                    padding: '20px',
                    paddingBottom: '66px',
                    lineHeight: '70px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            var yhPoint = new BMap.Point(109.52228,38.507806);
            var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            map.addOverlay(yhMaker);   
             var yhContent = '<div>金禾来肉牛养殖基地</div><p style="margin-top: 45px">饲养量：1990</p>';
                var yhLabel = new BMap.Label(yhContent, {       // 创建文本标注
                    position: yhPoint,
                    offset: new BMap.Size(90, -80)
                })  

                map.addOverlay(yhLabel);
            //     var that = this;
            // yhLabel.addEventListener('click', function(){
            //     that.$parent.tabsTypeClick('4');
            // })
            yhLabel.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-09/22479666b2904eca9b3cde4be6a265d10fd6b59ee9f639579fb14d9bdcf2c437.png")',
                    backgroundColor: 'transparent',
                    height: '208px',
                    padding: '15px 35px 46px 70px',
                    backgroundSize: '100% 100%'
                })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
    init() {
      this.qa.queryDatascreen16Info({}).then(res => {
        this.smallObj = res.data;
        this.smallObj.deviceEnterpriseRelationBOS[0].deviceUrl = 'ezopen://open.ys7.com/K23431934/6.live'
        this.smallObj.deviceEnterpriseRelationBOS[1].deviceUrl = 'ezopen://open.ys7.com/K23431934/27.live'
        this.smallObj.deviceEnterpriseRelationBOS[2].deviceUrl = 'ezopen://open.ys7.com/K23431934/9.live'
        this.smallObj.deviceEnterpriseRelationBOS[3].deviceUrl = 'ezopen://open.ys7.com/K23431934/16.live'
        console.log(this.smallObj.deviceEnterpriseRelationBOS)
        this.isTrue = true;
        let count = parseInt(this.smallObj.allBreedMaleCount) + parseInt(this.smallObj.allBreedFemaleCount) + parseInt(this.smallObj.allBredBreedCount) + parseInt(this.smallObj.allLambBreedCount);
        this.potatoData = res.data.inventoryBOS;
        this.isShow = true;
        //人员比例echarts
        let list = [];
        for(let i = 0; i < this.smallObj.jobBOS.length; i++) {
          this.renCount += parseInt(this.smallObj.jobBOS[i].jobPersonCount);
          let obj = {};
          obj.value = this.smallObj.jobBOS[i].jobPersonCount;
          obj.name = this.smallObj.jobBOS[i].jobName + ' ' + this.smallObj.jobBOS[i].jobPersonCount;
          obj.itemStyle = {
            color: `rgb(${parseInt(Math.random()*255)},${parseInt(Math.random()*255)},${parseInt(Math.random()*255)})`
          }
          list.push(obj)
        }
        this.$nextTick(function () {
          this.$refs.renyuan.Rydata = list;
          this.$refs.renyuan.actualCount();
        })

        //本场牛肉价格指数（元/斤）
        let xsyf2 = [];
        let xssl2 = [];
        for(let i = 0; i < this.smallObj.priceBOS.length; i++) {
          let a=this.smallObj.priceBOS[i].yearMonthDate.substring(5,7)
          xsyf2.push(Number(a)+'月');
          xssl2.push(parseInt(this.smallObj.priceBOS[i].price));
        }
        this.$nextTick(function () {
          this.$refs.xsjg.xsyf = xsyf2;
          this.$refs.xsjg.xssl = xssl2;
          this.$refs.xsjg.moreChart();
        })

        //年存栏出栏量
        let jcyf = [];
        let mysl1 = [];
        let mysl2 = [];
        for(let i = 0; i < this.smallObj.statisticsBOS1.length; i++) {
          // let a=this.smallObj.statisticsBOS1[i].yearMonthDate.substring(5,7)
          jcyf.push(this.smallObj.statisticsBOS1[i].yearMonthDate);
          mysl1.push(parseInt(this.smallObj.statisticsBOS1[i].reserved1));
          mysl2.push(parseInt(this.smallObj.statisticsBOS1[i].count));
        }
        this.$nextTick(function () {
          this.$refs.jcmy.yfLists = jcyf;
          this.$refs.jcmy.zhiLists1 = mysl1;
          this.$refs.jcmy.zhiLists2 = mysl2;
          this.$refs.jcmy.man();
        })

        //月存栏量
        let yfyf = [];
        let yfsl = [];
        for(let i = 0; i < this.smallObj.statisticsMothBOS.length; i++) {
          let a=this.smallObj.statisticsMothBOS[i].yearMonthDate.substring(5,7)
          yfyf.push(Number(a)+'月');
          yfsl.push(parseInt(this.smallObj.statisticsMothBOS[i].reserved2));
        }
        this.$nextTick(function () {
          this.$refs.yfy.yfyf = yfyf;
          this.$refs.yfy.yfsl = yfsl;
          this.$refs.yfy.man();
        })

        //月进出栏量
        let xsyf = [];
        let yssl1 = [];
        let yssl2 = [];
        for(let i = 0; i < this.smallObj.statisticsMothBOS2.length; i++) {
          let a=this.smallObj.statisticsMothBOS2[i].yearMonthDate.substring(5,7)
          xsyf.push(Number(a)+'月');
          yssl1.push(parseInt(this.smallObj.statisticsMothBOS2[i].reserved1));
          yssl2.push(parseInt(this.smallObj.statisticsMothBOS2[i].count));
        }
        this.$nextTick(function () {
          this.$refs.xsgy.xsyf = xsyf;
          this.$refs.xsgy.xssl1 = yssl1;
          this.$refs.xsgy.xssl2 = yssl2;
          this.$refs.xsgy.moreChart();
        })

      })
    },
    doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        doPlay2(_url){
            this.$nextTick(() => {
                 var player = cyberplayer('video111').setup({
                    width: 3080, // 宽度，也可以支持百分比（不过父元素宽度要有）
                    height: 1560, // 高度，也可以支持百分比
                    title: '111', // 标题
                    isLive: true, // 必须设置，表明是直播视频
                    file: _url, // //您的视频源的地址（目前是乐橙示例播放地址）
                    image: '', // 预览图
                    autostart: true, // 是否自动播放
                    stretching: "uniform", // 拉伸设置
                    repeat: true, // 是否重复播放
                    volume: 0, // 音量，注：仅当用户同意、网站由用户激活或媒体无声时允许自动播放
                    controls: true, // 是否显示控制栏
                    hls: {
                        reconnecttime: 5 // hls直播重连间隔秒数
                    },
                    ak: "a60324c9eed249a7812c629f4d10ee14" // 百度智能云平台注册（https://cloud.baidu.com）即可获得accessKey
                });
            })
           
        },
    transitionFun(_url,_title, type) {
            this.show = !this.show;
            if(type == "HK") {
                this.doPlay(_url);
            } else {
                this.doPlay2(_url);
            }
            

            
            this.vedioTitle = _title;
        },
  }
}
</script>

<style scoped>
.box {
  color: #fff;
}
.item-box {
  position: relative;
}
.box_j {
  width: 61px;
  height: 61px;
  position: absolute;
}
.lt_ {
  background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
  background-size: 100% 100%;
  top: -5px;
  left: -5px;
}
.rt_ {
  background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
  background-size: 100% 100%;
  top: -5px;
  right: -5px;
}
.lb_ {
  background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
  background-size: 100% 100%;
  left: -5px;
  bottom: -5px;
}
.rb_ {
  background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
  background-size: 100% 100%;
  right: -5px;
  bottom: -5px;
}
.map-box {
  position: relative;
}
.map-left {
  margin-top: 247px;
  position: relative;
  z-index: 999;
}
.map-left-item {
  width: 798px;
  height: 311px;
  display: flex;
  align-items: center;
  margin-bottom: 135px;
  background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-left: 68px;
}
.map-left-icon {
  width: 261px;
  height: 228px;
  background: url(../../../assets/images/dataScreen/ds_icon10.png) no-repeat;
  background-size: 90% 90%;
}
.map-left-icon-2 {
  background: url(../../../assets/images/dataScreen/ds_icon11.png) no-repeat;
  background-size: 95% 95%;
}
.map-left-icon-3 {
  background: url(../../../assets/images/dataScreen/ds_icon12.png) no-repeat;
  background-size: 95% 95%;
}
.map-img {
  width: 3818px;
  height: 3083px;
  /* background: url(../../../assets/images/dataScreen/map1.png) no-repeat; */
  background-size: 100% 100%;
  position: absolute;
  z-index: 100;
  top: -100px;
  left: 0;
}
.map-text-box {
  box-sizing: border-box;
  padding: 30px 0 0 65px;
  width: 520px;
  height: 192px;
  position: relative;
  left: 1217px;
  top: 1090px;
  background: url(../../../assets/images/dataScreen/bg8-map-icon.png) no-repeat;
  background-size: 100% 100%;
}
.map-text-box-title {
  color: #fff;
  font-size: 46px;
}
.map-text-box-icon{
  position: absolute;
  left: -110px;
  top: 55px;
}
.map-text-box-line {
  width: 441px;
  height: 1px;
  background: #C5CC11;
  border: 1px solid #F1F751;
  opacity: 0.3;
  margin: 8px 0 12px 0;
}
.map-text-box-title-2 {
  color: #fff;
  font-size: 40px;
}
.right {
  width: 9591px;
  margin-left: 3202px;
}
.header {
  width: 7920px;
  height: 69px;
  background: url(../../../assets/images/dataScreen/sheep_title.png) no-repeat;
  background-size: 100% 100%;

  text-align: center;

  margin: 30px auto 100px;
}
.header-text {
  width: 7920px;
  height: 69px;
  color: #fff;
  font-size: 90px;
  margin: 40px auto 0;
  text-align: center;
}
.content_left {
  width: 2537px;
}
.content_left_top {
  width: 100%;
  height: 976px;
  background: rgba(6, 58, 141, .1);
}
.content_left_bottom {
  width: 100%;
  height: 829px;
  background: rgba(6, 58, 141, .1);
}
.content_left_center {
  width: 100%;
  height: 710px;
  margin-top: 47px;
}
.content_left_center_left {
  width: 2478px;
  height: 798px;
  background: rgba(6, 58, 141, .1);
}
.content_left_center_right {
  width:930px;
  height: 798px;
  position: relative;
  background: rgba(6, 58, 141, .1);
}
.left_title {
  display: flex;
  align-items: center;
  height: 176px;
  line-height: 176px;
  position: relative;
  background: rgba(6, 58, 141, .13);
  color: #fff;
  box-sizing: border-box;
  padding-left: 76px;
}
.shujuTableCls {
  padding: 0 20px;
}
.shujuTableCls >.vedioCls {
  margin-top: 30px;
}
.shujuTableCls >.vedioCls:nth-child(2n) {
  margin-left: 70px;
}
.fk_b {
  width: 60px;
  height: 60px;
  background: #00D7FC;
}
.fk_y {
  width: 60px;
  height: 60px;
  background: #C6AC26;
}
.icon-left {
  width: 61px;
  height: 61px;
  margin-right: 39px;
}
.title-line {
  width: 311px;
  height: 30px;
  background:url(../../../assets/images/dataScreen/sheep/title_line.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 177px;
  bottom: 8px;
}
.title-line-sub {
  width: 311px;
  height: 30px;
  background:url(../../../assets/images/dataScreen/sheep/line-sheep.png) no-repeat;
  background-size: 100% 100%;
}
.sheep-m {
  width: 124px;
  height: 134px;
  background:url(../../../assets/images/dataScreen/sheep/sheep-m.png) no-repeat;
  background-size: 100% 100%;
  margin-right: 38px;
}
.sheep-m-line {
  width: 385px;
  height: 16px;
  background:url(../../../assets/images/dataScreen/sheep/sheep-m-icon.png) no-repeat;
  background-size: 100% 100%;
}
.sheep-m-line-2 {
  width: 307px;
  height: 16px;
}
.sheep-m-line-3 {
  width: 288px;
  height: 16px;
}
.sheep-line-s {
  width: 8px;
  height: 295px;
  background:url(../../../assets/images/dataScreen/sheep/line-s.png) no-repeat;
  background-size: 100% 100%;
  margin: 100px 54px 0 73px;
}
.marquee-list {
  height: 600px;
  overflow: hidden;
}
.echarts1 {
  width:100%;
  height: 500px;
  position: relative;
}
.echarts-num {
  position: absolute;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 90px;
  font-weight: 600;
  color: #fff;
  z-index: 500;
  height: 90px;
  text-align: center;
}
.echarts2 {
  width: 100%;
  height: 489px;
}
.line-jl {
  width: 40px;
  height: 12px;
}
.jl-box {
  width: 1213px;
  height: 104px;
  line-height: 104px;
  margin: 0 auto;
  text-align: center;
  background: #1D51A4;
}
.left_bottom_left {
  width: 1815px;
}

.heng {
  background-image: linear-gradient(to right, #01d7fc , #0c7794);
  height: 55px;
  border-radius: 30px;
}
.xh-line1 {

  height: 55px;
  background:url(../../../assets/images/dataScreen/sheep/xh-line1.png) no-repeat;
  background-size: 100% 100%;
}
.xh-line2 {
  width: 1176px;
  height: 55px;
  background:url(../../../assets/images/dataScreen/sheep/xh-line2.png) no-repeat;
  background-size: 100% 100%;
}
.xh-line3 {
  width: 804px;
  height: 55px;
  background:url(../../../assets/images/dataScreen/sheep/xh-line.png) no-repeat;
  background-size: 100% 100%;
}
.xh-line {
  width: 2px;
  height: 403px;
  background: #FFFFFF;
  opacity: 0.29;
}
.icon-left-y {
  width: 97px;
  height: 97px;
}
.content_center {
  width: 3459px;
  height: 2673px;
  position: relative;
}
.tabs_btn_box {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5555;
}

.tabs_btn {
  cursor: pointer;

}
.tabs_line {
  width: 358px;
  height: 60px;
  margin-top: 20px;
  background:url(../../../assets/images/dataScreen/sheep/title_line.png) no-repeat;
  background-size: 100% 100%;
}
.content_right {
  width: 3456px;
  height: 976px;
  position: relative;
  background: rgba(6, 58, 141, .1)
}
.meteorological-icon {
  width: 174px;
  height: 174px;
  margin: 40px 0 30px;
}
.meteorological-icon-1 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological1.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-2 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological2.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-3 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological3.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-4 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological4.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-5 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological5.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-6 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological6.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-7 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological7.png) no-repeat;
  background-size: 100% 100%;
}
.meteorological-icon-8 {
  background:url(../../../assets/images/dataScreen/sheep/meteorological8.png) no-repeat;
  background-size: 100% 100%;
}
.left {
  width: 1600px;
}
.task_item {
  width: 730px;
  height: 164px;
  background: linear-gradient(180deg, rgba(6,58,141,0) 0%, #134DA9 100%);
  border-radius: 22px;
  box-sizing: border-box;
  padding: 0 50px;
  opacity: .9;
  margin-right: 43px;
  margin-bottom: 43px;
}
.task_icon {
  width: 43px;
  height: 51px;
}
.task_box {
  width: 1740px;
}
.task_line {
  width: 4px;
  height: 376px;
  margin-top: 80px;
  border: 2px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.05), rgba(0, 171, 254, 1), rgba(255, 255, 255, 0.05)) 2 2;
}
.deal-with {
  width: 950px;
  height: 124px;
  line-height: 124px;
  background: rgba(135, 89, 233, .1);
}
.s-title-cls-list {
  margin-top: 10px;
  height: 230px;
  overflow: hidden;
}
.w1-cls{
  width: 47px;
  height: 47px;
  background-image: url(../../../assets/images/dataScreen/sheep/yj1.png);
}
.w2-cls{
  width: 47px;
  height: 47px;
  background-image: url(../../../assets/images/dataScreen/sheep/yj2.png);
}
.warn-title-cls {
  margin-bottom: 20px;
}
.echarts3 {
  width: 100%;
  height: 562px;
}
.echarts5 {
  width: 3229px;
  height:859px;
  margin-top: 50px;
}
.echarts8 {
  width: 3229px;
  height:771px;
}
.vedioTitleCls {
  background: -webkit-linear-gradient(left, #072D64 , #091729);
  font-size: 52px;
  color: #fff;
  width: 1200px;
  height: 134px;
  line-height: 134px;
  text-indent: 40px;
  margin-top:40px;
}
.map-click-left {
  position: absolute;
  cursor: pointer;
}
.bubble {
  height: 208px;
  box-sizing: border-box;
  padding: 15px 35px 46px;
  background: url(../../../assets/images/dataScreen/pop8.png) no-repeat;
  background-size: 100% 100%;
}
.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videdo-box {
    display: flex;
    align-items: center;
}
.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}
.video-title {
    font-size: 65px;
    color:#fff;
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}
.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;    
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;    
}
.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}
</style>