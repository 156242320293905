<template>
    <div class="box flex">
        <div class="map-box">
            <div class="map-left">
                <div class="map-left-item  animate__animated animate__bounceInDown">
                    <div class="map-left-icon-1"></div>
                    <div style="margin-left: 53px;margin-top: -35px;">
                        <div class="numCls">{{siteNumber}}<span class="dwCls">个</span></div>
                        <div class="titleCls">站点数量</div>
                    </div>
                </div>
            </div>
            <div class="map-img">
                <baidu-map v-if="isShow" :center="center" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
                </baidu-map>
            </div>
        </div>

        <div class="right animate__animated animate__fadeInRight">
            <div class="soil-testing">
                <div class="soil-title">
                    <div style="display:flex;align-items:center;">
                        <div class="soil-icon"></div>
                        <div>气象土壤监测站</div>
                    </div>
                </div>
                <div class="coil-content">
                    <div class="content-item">
                        <div class="item-icon item-icon1"></div>
                        <div>站点名称：<span style="color:#0FCCFF;">{{logObj.siteName}}</span></div>
                    </div>
                    <div class="content-item">
                        <div style="width:48px;margin-right:37px;">
                            <div class="item-icon item-icon2"></div>
                        </div>
                        <div style="flex: 1;">站点位置：<span style="color:#0FCCFF;">东经{{logObj.siteLongitudeLatitude?logObj.siteLongitudeLatitude.split(',')[0]:''}}° ，北纬{{logObj.siteLongitudeLatitude?logObj.siteLongitudeLatitude.split(',')[1]:''}}°</span></div>
                    </div>
                    <div class="content-item">
                        <div class="item-icon item-icon3"></div>
                        <div>站点型号：<span style="color:#0FCCFF;">{{logObj.deviceModel}}</span></div>
                    </div>
                    <div class="content-item">
                        <div class="item-icon item-icon4"></div>
                        <div>站点类型：<span style="color:#0FCCFF;">{{logObj.deviceType}}</span></div>
                    </div>
                    <div class="content-item">
                        <div class="item-icon item-icon5"></div>
                        <div>建站时间：<span style="color:#0FCCFF;">{{logObj.constructionTime}}</span></div>
                    </div>
                    <div class="content-item">
                        <div class="item-icon item-icon6"></div>
                        <div>运行状态：<span style="color:#0FCCFF;">{{logObj.deviceState}}</span></div>
                    </div>
                    <div class="content-item" style="margin-bottom:0;">
                        <div class="item-icon item-icon7"></div>
                        <div>运行天数：<span style="color:#0FCCFF;">32天</span></div>
                    </div>
                </div>
                <div class="soil-title title-bg">
                    <div style="display:flex;align-items:center;">
                        <div class="soil-icon"></div>
                        <div>气象土壤监测站运行日志</div>
                    </div>
                </div>
                <div style="width:100%;overflow:hidden;">
                    <div class="log-content">
                        <div class="log-item" :class="index%2==1?'log-item-active':''" v-for="(item,index) in logObj.adminLogVOList" :key="index">
                            <div>{{item.time}}</div>
                            <div style="display:flex;align-items:center;">
                                <img style="display:block;width:69px;height:69px;margin-right:29px;" v-if="item.b" src="../../../assets/images/dataScreen/zhengchang.png" alt="">
                                <img style="display:block;width:69px;height:69px;margin-right:29px;" v-else src="../../../assets/images/dataScreen/yichang.png" alt="">
                                <span :class="item.b?'log-item-zc':'log-item-yc'">{{item.b?'正常':'异常'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-top-left-corner"></div>
            </div>
            <div>
                <div class="tab-box">
                    <div style="display:flex;align-items:center;justify-content: space-between;width:2050px;">
                        <div class="tab-pane" :class="tabActive==index?'tab-active':''" v-for="(item,index) in tabList" :key="index" @click="tabClick(index)">
                            <div>{{item.label}}</div>
                            <div :class="tabActive==index?'active-icon':'tab-icon'"></div>
                        </div>
                    </div>
                </div>
                <div style="display:flex;">
                    <div class="soil-testing" style="width:1007px;margin-left:85px;margin-right:80px;margin-top:0;" v-if="tabActive==0">
                        <div class="soil-title" style="width:100%;">
                            <div style="display:flex;align-items:center;">
                                <div class="soil-icon2"></div>
                                <div>实时气象监测数据</div>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon1"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formmatTemperature}}℃</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">空气温度</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon2"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatHumidity}}%RH</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">空气湿度</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon3"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatWindVelocity}}m/s</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">风速</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon4"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatWindDirection}}</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">风向</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon5"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatPrecipitation}}mm</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">降雨量</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon6"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatAtmosphere}}hPa</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">大气压</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon7"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatCo2}}ppm</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">二氧化碳浓度</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon8"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatLightIntensity}}Lux</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">光照强度</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon9"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatLightRadiate}}μmol/m²·s</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">光合有效辐射</span>
                            </div>
                        </div>
                        <div class="content-top-left-corner"></div>
                    </div>
                    <div class="soil-testing" style="width:1007px;margin-left:85px;margin-right:80px;margin-top:0;" v-if="tabActive==1">
                        <div class="soil-title" style="width:100%;">
                            <div style="display:flex;align-items:center;">
                                <div class="soil-icon2"></div>
                                <div>实时土壤监测数据</div>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon1"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatSoilTemperature}}℃</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">土壤温度</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon2"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatSoilMoisture}}%</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">土壤水分</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon10"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatSoilConductivity}}μS/cm</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">土壤电导率</span>
                            </div>
                        </div>
                        <div class="detection-item">
                            <div class="detection-icon11"></div>
                            <div style="text-align:center;">
                                <span style="display:block;text-align:center;font-size: 63px;color: #0FCCFF;">{{rowData.formatSoilSalt}}mg/L</span>
                                <span style="display:block;text-align:center;font-size: 50px;color: #FFFFFF;margin-top10px;">土壤盐分</span>
                            </div>
                        </div>
                        <div class="content-top-left-corner"></div>
                    </div>
                    <div class="soil-testing soilBg2" style="width:5532px;margin-top:0;" v-if="tabActive==0">
                        <div class="soil-title" style="width:100%;">
                            <div style="display:flex;align-items:center;">
                                <div class="soil-icon2"></div>
                                <div>气象监测数据分析 | {{dateActive1==0?'24小时':'月'}}</div>
                            </div>
                            <div style="display:flex;align-items:center;">
                                <div class="dateMonth" :class="dateActive1==0?'dateMonth-active':''" @click="chekcDate1(0)">日</div>
                                <div class="dateMonth" style="margin-right:47px;" :class="dateActive1==1?'dateMonth-active':''" @click="chekcDate1(1)">月</div>
                            </div>
                        </div>
                        <div class="echart-list">
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">空气温度</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：℃</div>
                                    </div>
                                </div>
                                <Highecharts1 ref="kqwd" class="zoushituCls"></Highecharts1>
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">空气湿度</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：%RH</div>
                                    </div>
                                </div>
                                <Highecharts2 ref="kqsd" class="zoushituCls"></Highecharts2>                                
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">风速</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：m/s</div>
                                    </div>
                                </div>
                                <Highecharts3 ref="tqfs" class="zoushituCls"></Highecharts3>                                
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">光照强度</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：Lux</div>
                                    </div>
                                </div>
                                <Highecharts4 ref="gzqd" class="zoushituCls"></Highecharts4>                                
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">光合有效辐射</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：μmol/m²·s</div>
                                    </div>
                                </div>
                                <Highecharts5 ref="ghyxfs" class="zoushituCls"></Highecharts5>                                
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">二氧化碳浓度</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：ppm</div>
                                    </div>
                                </div>
                                <Highecharts6 ref="eyhtnd" class="zoushituCls"></Highecharts6>                                
                            </div>
                        </div>
                        <div class="content-top-left-corner"></div>
                    </div>
                    <div class="soil-testing soilBg2" style="width:5532px;margin-top:0;" v-if="tabActive==1">
                        <div class="soil-title" style="width:100%;">
                            <div style="display:flex;align-items:center;">
                                <div class="soil-icon2"></div>
                                <div>土壤实时监测数据分析 | {{dateActive2==0?'24小时':'月'}}</div>
                            </div>
                            <div style="display:flex;align-items:center;">
                                <div class="dateMonth" :class="dateActive2==0?'dateMonth-active':''" @click="chekcDate2(0)">日</div>
                                <div class="dateMonth" style="margin-right:47px;" :class="dateActive2==1?'dateMonth-active':''" @click="chekcDate2(1)">月</div>
                            </div>
                        </div>
                        <div class="echart-list">
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">土壤温度</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：℃</div>
                                    </div>
                                </div>
                                <Highecharts7 ref="trwd" class="zoushituCls"></Highecharts7>
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">土壤水分</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：%</div>
                                    </div>
                                </div>
                                <Highecharts8 ref="trsf" class="zoushituCls"></Highecharts8>
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">土壤电导率</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：μS/cm</div>
                                    </div>
                                </div>
                                <Highecharts9 ref="trddl" class="zoushituCls"></Highecharts9>
                            </div>
                            <div class="echart-item">
                                <div style="display:flex;align-items:center;justify-content: space-between;">
                                    <div style="color:#fff;">土壤盐分</div>
                                    <div style="display:flex;align-items:center;">
                                        <div style="display:flex;align-items:center;margin-right:172px;">
                                            <div class="chartIcon"></div>
                                            <div>平均值</div>
                                        </div>
                                        <div>单位：mg/L</div>
                                    </div>
                                </div>
                                <Highecharts10 ref="tryf" class="zoushituCls"></Highecharts10>
                            </div>
                        </div>
                        <div class="content-top-left-corner"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKit from "ezuikit-js";
import cyberplayer from "../../../../static/cyberplayer.js"
import Highecharts1 from '../highecharts/dataScreen17/highecharts1.vue'
import Highecharts2 from '../highecharts/dataScreen17/highecharts2.vue'
import Highecharts3 from '../highecharts/dataScreen17/highecharts3.vue'
import Highecharts4 from '../highecharts/dataScreen17/highecharts4.vue'
import Highecharts5 from '../highecharts/dataScreen17/highecharts5.vue'
import Highecharts6 from '../highecharts/dataScreen17/highecharts6.vue'
import Highecharts7 from '../highecharts/dataScreen17/highecharts7.vue'
import Highecharts8 from '../highecharts/dataScreen17/highecharts8.vue'
import Highecharts9 from '../highecharts/dataScreen17/highecharts9.vue'
import Highecharts10 from '../highecharts/dataScreen17/highecharts10.vue'

export default {
    name: '',
    components: {
        EZUIKitJs2,
        EZUIKitJs,
        Highecharts1,
        Highecharts2,
        Highecharts3,
        Highecharts4,
        Highecharts5,
        Highecharts6,
        Highecharts7,
        Highecharts8,
        Highecharts9,
        Highecharts10,
    },
    data() {
        return {
            city: {},
            ddObj: {},
            renCount: 0,
            maxCount: 0,
            show: false,
            domHeight: 0,
            isShow: false,
            isYes: false,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            timer: null,
            potatoData: [],
            trademarkData: [],
            vegetableStatistics: [],
            nypzList: [],
            scdqList: [],
            mapData: [],
            qxList: {},
            tabActive: 0,
            dateActive1: 0,
            dateActive2: 0,
            tabList: [
                {
                    label: '站点气象环境监测分析',
                    value: 0,
                },
                {
                    label: '站点土壤环境监测分析',
                    value: 1,
                },
            ],
            townList: [],
            pointList: [],
            labels: [],
            siteNumber: 0,
            logObj: {},
            rowData: {},
            rowData1: {},
        }
    },

    mounted() {
        this.init();
    },
    methods: {
        getClickInfo () {},
        tabClick (i) {
            this.tabActive = i;
            this.dateActive1 = 0;
            this.dateActive2 = 0;
            this.chartListFun(this.rowData1,1);
        },
        chekcDate1 (i) {
            this.dateActive1 = i;
            this.checkDay();
        },
        chekcDate2 (i) {
            this.dateActive2 = i;
        },
        checkEchartFun() {
            if (this.tabActive == 0) {
                this.$nextTick(() => {
                    this.$refs.kqwd.moreChart();
                    this.$refs.kqsd.moreChart();
                    this.$refs.tqfs.moreChart();
                    this.$refs.gzqd.moreChart();
                    this.$refs.ghyxfs.moreChart();
                    this.$refs.eyhtnd.moreChart();
                })
            }
        },
        checkDay() {
            this.$nextTick(() => {
                let now = new Date();
                let year = now.getFullYear();
                let month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1; // 月份是从0开始的，所以需要加1
                let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
                let time = year + '-' + month + '-' + day;
                this.qa.monthFindQx({siteName: this.rowData1.siteName, time: time}).then(res => {
                    this.rowData = res.data[0];
                    console.log('-------row1',this.rowData)
                })
                let newArr1 = [];
                let newArr2 = [];
                let newArr3 = [];
                let newArr4 = [];
                let newArr5 = [];
                let newArr6 = [];
                let newArr7 = [];
                let newArr8 = [];
                let newArr9 = [];
                let newArr10 = [];
                if (this.tabActive == 0) {
                    if (this.dateActive1 == 1) {
                        let month = ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];                    
                        this.qa.monthFindTb({siteName: this.rowData1.siteName, time: time}).then(res => {
                            this.$refs.kqwd.isInit = true;
                            this.$refs.kqsd.isInit = true;
                            this.$refs.tqfs.isInit = true;
                            this.$refs.gzqd.isInit = true;
                            this.$refs.ghyxfs.isInit = true;
                            this.$refs.eyhtnd.isInit = true;
                            for (const key in res.data.airTemperatureMap) { //温度
                                newArr1.push(res.data.airTemperatureMap[key])
                            }
                            for (const key in res.data.airHumidityMap) {  //湿度
                                newArr2.push(res.data.airHumidityMap[key])
                            }
                            for (const key in res.data.windVelocityMap) {  //风速
                                newArr3.push(res.data.windVelocityMap[key])
                            }
                            for (const key in res.data.co2Map) {  // 二氧化碳浓度
                                newArr4.push(res.data.co2Map[key])
                            }
                            for (const key in res.data.lightIntensityMap) {  //光照强度
                                newArr5.push(res.data.lightIntensityMap[key])
                            }
                            for (const key in res.data.lightRadiateMap) {  // 光合有效辐射
                                newArr6.push(res.data.lightRadiateMap[key])
                            }
                            for (const key in res.data.soilConductivityMap) {  // 土壤电导率
                                newArr7.push(res.data.soilConductivityMap[key])
                            }
                            for (const key in res.data.soilMoistureMap) {  //土壤水分
                                newArr8.push(res.data.soilMoistureMap[key])
                            }
                            for (const key in res.data.soilSaltMap) {  //土壤盐分
                                newArr9.push(res.data.soilSaltMap[key])
                            }
                            for (const key in res.data.soilTemperatureMap) {  //土壤温度
                                newArr10.push(res.data.soilTemperatureMap[key])
                            }
                            this.$refs.kqwd.xData = month;
                            this.$refs.kqwd.yData = newArr1;
                            this.$refs.kqsd.xData = month;
                            this.$refs.kqsd.yData = newArr2;
                            this.$refs.tqfs.xData = month;
                            this.$refs.tqfs.yData = newArr3;
                            this.$refs.gzqd.xData = month;
                            this.$refs.gzqd.yData = newArr5;
                            this.$refs.ghyxfs.xData = month;
                            this.$refs.ghyxfs.yData = newArr6;
                            this.$refs.eyhtnd.xData = month;
                            this.$refs.eyhtnd.yData = newArr4;
                            this.$refs.kqwd.moreChart();
                            this.$refs.kqsd.moreChart();
                            this.$refs.tqfs.moreChart();
                            this.$refs.gzqd.moreChart();
                            this.$refs.ghyxfs.moreChart();
                            this.$refs.eyhtnd.moreChart();
                        })
                    } else {
                        this.chartListFun(this.rowData1,1)
                    }
                } else {
                    
                }
            })
        },
        chartListFun(data,type) {
            console.log('----data1',data)
            let now = new Date();
            let year = now.getFullYear();
            let month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1; // 月份是从0开始的，所以需要加1
            let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
            let time = year + '-' + month + '-' + day;
            this.qa.hourFindg({siteName: data.siteName, time: time}).then(res => {
                console.log('----data',res.data)
                this.rowData = this.formatObj(res.data);
                console.log('-------row2',this.rowData)                
                let newArr1 = [];
                let newArr2 = [];
                let newArr3 = [];
                let newArr4 = [];
                let newArr5 = [];
                let newArr6 = [];
                let newArr7 = [];
                let newArr8 = [];
                let newArr9 = [];
                let newArr10 = [];
                let date = ['0时', '1时', '2时', '3时', '4时', '5时', '6时', '7时', '8时', '9时', '10时', '11时', '12时', '13时', '14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时'];                
                for (const key in res.data.airTemperatureMap) { //温度
                    newArr1.push(res.data.airTemperatureMap[key])
                }
                for (const key in res.data.airHumidityMap) {  //湿度
                    newArr2.push(res.data.airHumidityMap[key])
                }
                for (const key in res.data.windVelocityMap) {  //风速
                    newArr3.push(res.data.windVelocityMap[key])
                }
                for (const key in res.data.co2Map) {  // 二氧化碳浓度
                    newArr4.push(res.data.co2Map[key])
                }
                for (const key in res.data.lightIntensityMap) {  //光照强度
                    newArr5.push(res.data.lightIntensityMap[key])
                }
                for (const key in res.data.lightRadiateMap) {  // 光合有效辐射
                    newArr6.push(res.data.lightRadiateMap[key])
                }
                for (const key in res.data.soilConductivityMap) {  // 土壤电导率
                    newArr7.push(res.data.soilConductivityMap[key])
                }
                for (const key in res.data.soilMoistureMap) {  //土壤水分
                    newArr8.push(res.data.soilMoistureMap[key])
                }
                for (const key in res.data.soilSaltMap) {  //土壤盐分
                    newArr9.push(res.data.soilSaltMap[key])
                }
                for (const key in res.data.soilTemperatureMap) {  //土壤温度
                    newArr10.push(res.data.soilTemperatureMap[key])
                }
                if (this.tabActive == 0) {
                    if (type == 1) {
                        this.$refs.kqwd.isInit = true;
                        this.$refs.kqsd.isInit = true;
                        this.$refs.tqfs.isInit = true;
                        this.$refs.gzqd.isInit = true;
                        this.$refs.ghyxfs.isInit = true;
                        this.$refs.eyhtnd.isInit = true;
                    }
                    this.$refs.kqwd.xData = date;
                    this.$refs.kqwd.yData = newArr1;
                    this.$refs.kqsd.xData = date;
                    this.$refs.kqsd.yData = newArr2;
                    this.$refs.tqfs.xData = date;
                    this.$refs.tqfs.yData = newArr3;
                    this.$refs.gzqd.xData = date;
                    this.$refs.gzqd.yData = newArr5;
                    this.$refs.ghyxfs.xData = date;
                    this.$refs.ghyxfs.yData = newArr6;
                    this.$refs.eyhtnd.xData = date;
                    this.$refs.eyhtnd.yData = newArr4;   

                    this.$refs.kqwd.moreChart();
                    this.$refs.kqsd.moreChart();
                    this.$refs.tqfs.moreChart();
                    this.$refs.gzqd.moreChart();
                    this.$refs.ghyxfs.moreChart();
                    this.$refs.eyhtnd.moreChart();
                } else {
                    if (type == 1) {
                        this.$refs.trwd.isInit = true;
                        this.$refs.trsf.isInit = true;
                        this.$refs.trddl.isInit = true;
                        this.$refs.tryf.isInit = true;
                    }
                    this.$refs.trwd.xData = date;
                    this.$refs.trwd.yData = newArr10;
                    this.$refs.trsf.xData = date;
                    this.$refs.trsf.yData = newArr8;
                    this.$refs.trddl.xData = date;
                    this.$refs.trddl.yData = newArr7;
                    this.$refs.tryf.xData = date;
                    this.$refs.tryf.yData = newArr9;
                    this.$nextTick(() => {
                        this.$refs.trwd.moreChart();
                        this.$refs.trsf.moreChart();
                        this.$refs.trddl.moreChart();
                        this.$refs.tryf.moreChart();   
                    })                 
                }
            })
        },
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            for(let i = 0; i < this.potatoData.length; i++) {
                console.log(this.potatoData[i])
                var myIcon = new BMap.Icon(require(this.potatoData[i].active == true ? "../../../assets/images/dataScreen/zddw_select.png" : "../../../assets/images/dataScreen/zddw.png"), new BMap.Size(161, 195), {   
                    // 指定定位位置。  
                    // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                    // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                    // 图标中央下端的尖角位置。   
                    anchor: new BMap.Size(15, 25),   
                    // 设置图片偏移。  
                    // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                    // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                    // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
                });  
                // if(this.potatoData[i].latitude > 0 && this.potatoData[i].longitude > 0) {
                var point = new BMap.Point(this.potatoData[i].longitude, this.potatoData[i].latitude);
                console.log(point)
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   

                var content = "<div style='color: #fff; display: flex; align-items: center;font-size:60px;'><span>"+ this.potatoData[i].currentHourValue+"</span></div>"
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(60, 50)
                })  
                this.pointList.push(marker);
                this.labels.push(label);
                map.addOverlay(label);
                this.clickBox(this.potatoData[i], i);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundColor: 'transparent',
                    // padding: '30px 35px 120px',
                    backgroundSize: '100% 100%',
                    zIndex: 1000
                })
            }

            for(let i = 0; i < this.townList.length; i++) {
                // if(this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) {
                var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -368)
                })  
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    // backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                    backgroundColor: 'transparent',
                    height: '348px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
            }
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            map.enableScrollWheelZoom(true);
        },
        clickBox(row, index) {
          var that = this;
          this.pointList[index].addEventListener('click', function(){
            console.log(row)
            that.getSiteLog({id: row.id,deviceNumber: row.deviceNumber})
            that.rowData = that.formatObj(row.rawData)
            that.rowData1 = row.data;
            that.tabActive = 0;
            that.dateActive1 = 0;
            that.chartListFun(row.rawData,1)
            console.log('------row3',that.rowData)
          })  
        },
        gps(){
            this.qa.siteFindAll({}).then(res => {
                this.siteNumber = 0;
                let newArr = [];
                for(let i = 0; i < res.data.length; i++) {
                    let item = res.data[i].listSite;
                    for (let j = 0; j < item.length; j++) {
                        newArr.push(item[j])
                    }
                    
                }

                for (let j = 0; j < newArr.length; j++) {
                    newArr[j].longitude = Number(newArr[j].siteLongitudeLatitude.split(',')[0]);
                    newArr[j].latitude = Number(newArr[j].siteLongitudeLatitude.split(',')[1]);
                    newArr[j].active = false;
                    let now = new Date();
                    let year = now.getFullYear();
                    let month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1; // 月份是从0开始的，所以需要加1
                    let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
                    let time = year + '-' + month + '-' + day;
                    this.qa.hourFindg({siteName: newArr[j].siteName, time: time}).then(res => {
                        newArr[j].rawData = res.data;
                        newArr[j].rawData.siteName = newArr[j].siteName;
                        let formattedResult = Object.entries(newArr[j].rawData.airTemperatureMap).reduce((acc, [key, value]) => {
                          acc[key.split(":")[0]] = value
                          return acc
                        }, {})
                        let currentHour = new Date().getHours()
                        let formattedHour = currentHour.toString().padStart(2, "0")
                        // 查找当前小时对应的值
                //         let currentHourValue = formattedResult[formattedHour]
                        newArr[j].currentHourValue = formattedResult[formattedHour] !='' ? formattedResult[formattedHour] +'°C': '';
                        console.log(`当前时间为 ${formattedHour}:00，对应的值是: ${newArr[j].currentHourValue}`);
                    })
                    var point = new BMap.Point(newArr[j].longitude, newArr[j].latitude);
                    var geoc = new BMap.Geocoder();
                    var that = this;
                    geoc.getLocation(point, function(rs){
                        that.potatoData.push(newArr[j]);
                        console.log(that.potatoData)
                        setTimeout(() => {
                            that.isShow = true;
                        },1000)
                        that.$nextTick(() => {
                            that.siteNumber++;
                        })
                    })
                }
                setTimeout(() => {
                   if (this.potatoData && this.potatoData.length > 0) {
                        this.getSiteLog({id: this.potatoData[0].id,deviceNumber: this.potatoData[0].deviceNumber})
                        this.rowData = that.formatObj(this.potatoData[0].rawData)
                        console.log('-------row4',this.rowData)                        
                        this.rowData1 = this.potatoData[0].rawData;
                        this.chartListFun(this.potatoData[0].rawData,0)
                    }
                },1000)
                // console.log(this.potatoData)
                // console.log(1212121212121212)
                // this.isShow = true;
            })
        },
        formatObj(row) {
            console.log(row)
            // 温度
            let formmatTemperature = Object.entries(row.airTemperatureMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 湿度
            let formatHumidity = Object.entries(row.airHumidityMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 风速
            let formatWindVelocity = Object.entries(row.windVelocityMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 风向
            let formatWindDirection = Object.entries(row.windDirectionMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 降雨量
            let formatPrecipitation = Object.entries(row.precipitationMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 大气压
            let formatAtmosphere = Object.entries(row.atmosphereMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 二氧化碳浓度
            let formatCo2 = Object.entries(row.co2Map).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 光照强度
            let formatLightIntensity = Object.entries(row.lightIntensityMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 光合有效辐射
            let formatLightRadiate = Object.entries(row.lightRadiateMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 土壤电导率
            let formatSoilConductivity = Object.entries(row.soilConductivityMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 土壤水分
            let formatSoilMoisture = Object.entries(row.soilMoistureMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 土壤PH
            let formatSoilPh = Object.entries(row.soilPhMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 土壤盐分
            let formatSoilSalt = Object.entries(row.soilSaltMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})
            // 土壤温度
            let formatSoilTemperature = Object.entries(row.soilTemperatureMap).reduce((acc, [key, value]) => {
              acc[key.split(":")[0]] = value
              return acc
            }, {})

            let currentHour = new Date().getHours()
            let formattedHour = currentHour.toString().padStart(2, "0")
            // 查找当前小时对应的值
            // let currentHourValue = formattedResult[formattedHour]
            let obj = {
                formmatTemperature: '',
                formatHumidity: '',
                formatWindVelocity: '',
                formatWindDirection: '',
                formatPrecipitation: '',
                formatAtmosphere: '',
                formatCo2: '',
                formatLightIntensity: '',
                formatLightRadiate: '',
                formatSoilConductivity: '',
                formatSoilMoisture: '',
                formatSoilPh: '',
                formatSoilSalt: '',
                formatSoilTemperature: '',
            }
            obj.formmatTemperature = formmatTemperature[formattedHour];
            obj.formatHumidity = formatHumidity[formattedHour];
            obj.formatWindVelocity = formatWindVelocity[formattedHour];
            obj.formatWindDirection = formatWindDirection[formattedHour];
            obj.formatPrecipitation = formatPrecipitation[formattedHour];
            obj.formatAtmosphere = formatAtmosphere[formattedHour];
            obj.formatCo2 = formatCo2[formattedHour];
            obj.formatLightIntensity = formatLightIntensity[formattedHour];
            obj.formatLightRadiate = formatLightRadiate[formattedHour];
            obj.formatSoilConductivity = formatSoilConductivity[formattedHour];
            obj.formatSoilMoisture = formatSoilMoisture[formattedHour];
            obj.formatSoilPh = formatSoilPh[formattedHour];
            obj.formatSoilSalt = formatSoilSalt[formattedHour];
            obj.formatSoilTemperature = formatSoilTemperature[formattedHour];
            console.log(obj)
            return obj;
        },
        getSiteLog(data) {
            this.qa.siteLog(data).then(res => {
               console.log(res)
               this.logObj = res.data;
               this.logObj.adminLogVOList.forEach(item => {
                   console.log(item.time)
                   if (!item.b) {
                       let date = new Date(item.time);
                       const formattedDate = date.toLocaleString('zh-CN', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            fractionalSecondDigits: 3,
                            hour12: false // 使用24小时制
                        });
                       item.time = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getDate()}- ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
                   }
               })
           })
        },
        init(){
           // this.city = JSON.parse(sessionStorage.getItem("chooseId"));
           this.qa.datascreen12TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
                this.gps();
            })
            // this.qa.queryDatascreen17Info({}).then(res => {
            //     this.ddObj = res.data;
            //     this.potatoData = res.data.workMapPOS;
            //     if(this.ddObj.workMapPOS != null && this.ddObj.workMapPOS.length > 0) {
            //         this.mapData = this.ddObj.workMapPOS
            //     }
            //     if(this.ddObj.trademarkPOS != null && this.ddObj.trademarkPOS.length > 0) {
            //         this.trademarkData = this.ddObj.trademarkPOS
            //     }

            //     if(this.ddObj.facilitiesPOS != null && this.ddObj.facilitiesPOS.length > 0) {
            //         this.vegetableStatistics = this.ddObj.facilitiesPOS
            //     }
            //     console.log(this.mapData)
            //     //基地人员
            //     let list = [];
            //     this.isShow = true;
            // })
        },
    }
}
</script>

<style scoped>
.item-box {
    position: relative;
}

.box_j {
    width: 61px;
    height: 61px;
    position: absolute;
}

.lt_ {
    background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
    background-size: 100% 100%;
    top: -5px;
    left: -5px;
}

.rt_ {
    background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
    background-size: 100% 100%;
    top: -5px;
    right: -5px;
}

.lb_ {
    background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
    background-size: 100% 100%;
    left: -5px;
    bottom: -5px;
}

.rb_ {
    background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
    background-size: 100% 100%;
    right: -5px;
    bottom: -5px;
}

.test {
    color: #ccc;
    font-size: 100px;
}

.map-box {
    position: relative;
}

.map-left {
    margin-top: 247px;
    position: relative;
    z-index: 999;
}

.map-left-item {
    width: 798px;
    height: 311px;
    margin-bottom: 135px;
    background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-left: 83px;
    display: flex;
    align-items: center;
    /*position: relative;*/
    font-size: 101px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

.map-left-icon {
    background: url(../../../assets/images/dataScreen/mountain-link-full.png) no-repeat;
    background-size: 100% 100%;
    /*position: absolute;
    left: 85px;*/
}

.numCls {
    /*width: 100%;
    margin-left: 260px;
    padding-top: 40px;
    padding-left: 53px;*/
}

div.numCls>span.dwCls {
    font-size: 40px;
}

.titleCls {
    width: 100%;
    /*height: 228px;*/
    /*margin-left: 270px;
    padding-left: 53px;*/
    font-size: 51px;
    color: #FFFFFF;
}

.map-left-icon-1 {
    width: 175px;
    height: 114px;
    background: url(../../../assets/images/dataScreen/zdgs.png) no-repeat;
    background-size: 100% 100%;
    /*position: absolute;
    top: 50%;
    transform: translateY(-50%);*/
}

.map-img {
    width: 4433px;
    height: 2845px;
    background: url(../../../assets/images/dataScreen/map1.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -50px;
    z-index: 100;
    left: 0;
}

.map-text-box {
    box-sizing: border-box;
    padding: 70px 0 0 170px;
    width: 1102px;
    height: 678px;
    position: relative;
    /* left: 1242px;
    top: 292px; */
    background: url(../../../assets/images/dataScreen/malinshu-map-text.png) no-repeat;
    background-size: 100% 100%;
}

.map-text-boxs {
    box-sizing: border-box;
    position: relative;
    width: 519px;
    height: 200px;
    left: 670px;
    top: 1445px;
    background: url(../../../assets/images/dataScreen/4-qiye.png) no-repeat;
    background-size: 100% 100%;
}

.map-text-boxs-title {
    font-size: 49px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2CFFFF;
    text-align: right;
    padding-top: 15px;
    padding-right: 25px;
}

.map-text-box-title {
    color: #fff;
    font-size: 40px;
}

.map-text-box-title-2 {
    color: #fff;
    opacity: .8;
    font-size: 53px;
}

.right {
    /*width: 8173px;*/
    margin-left: 4354px;
    display: flex;
}

.header {
    width: 7920px;
    height: 72px;
    background: url(../../../assets/images/dataScreen/s_title.png) no-repeat;
    background-size: 100% 100%;

    text-align: center;

    margin: 30px auto 0;
}

.header-text {
    width: 7920px;
    height: 72px;
    color: #fff;
    font-size: 90px;
    margin: 40px auto 0;
    text-align: center;
}
.soil-testing {
    width: 1444px;
    height: 2669px;
    background: url(../../../assets/images/dataScreen/soilTestingBg.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 311px;
    position: relative;
}
.soilBg2 {
    width: 5532px;
    height: 2669px;
    background: url(../../../assets/images/dataScreen/dataAnalysisBg.png) no-repeat;
    background-size: 100% 100%;
}
.soil-title {
    width: 1434px;
    height: 169px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 60px;
    padding-left: 101px;
    color: #fff;
}
.title-bg {
    width: 1428px;
    margin-left: 10px;
    background: #0C1A40;
}
.soil-icon {
    display: block;
    width: 74px;
    height: 74px;
    background: url(../../../assets/images/dataScreen/biaoji.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 27px;
    margin-top: 15px;
}
.soil-icon2 {
    display: block;
    width: 57px;
    height: 54px;
    margin-right: 26px;
    margin-top: 10px;
    background: url(../../../assets/images/dataScreen/titlelogo.png) no-repeat;
    background-size: 100% 100%;    
}
.coil-content {
    width: 1245px;
    height: 1062px;
    margin: 117px 0 114px 119px;
}
.content-item {
    display: flex;
    align-items: center;
    margin-bottom: 79px;
    font-size: 56px;
    color: #7FA2CE;
    white-space: nowrap;
}
.item-icon {
    display: block;
    margin-right: 38px;
}
.item-icon1 {
    width: 46px;
    height: 50px;
    background: url(../../../assets/images/dataScreen/dikuaimingcheng.png) no-repeat;
    background-size: 100% 100%;
}
.item-icon2 {
    width: 48px;
    height: 53px;
    background: url(../../../assets/images/dataScreen/dingwei.png) no-repeat;
    background-size: 100% 100%;
}
.item-icon3 {
    width: 44px;
    height: 52px;
    background: url(../../../assets/images/dataScreen/shebeixinghao.png) no-repeat;
    background-size: 100% 100%;
}
.item-icon4 {
    width: 48px;
    height: 48px;
    background: url(../../../assets/images/dataScreen/dengjileixingicon.png) no-repeat;
    background-size: 100% 100%;
}
.item-icon5 {
    width: 48px;
    height: 48px;
    background: url(../../../assets/images/dataScreen/shijian.png) no-repeat;
    background-size: 100% 100%;
}
.item-icon6 {
    width: 47px;
    height: 48px;
    background: url(../../../assets/images/dataScreen/zhuangtai.png) no-repeat;
    background-size: 100% 100%;
}
.item-icon7 {
    width: 48px;
    height: 51px;
    background: url(../../../assets/images/dataScreen/riqi.png) no-repeat;
    background-size: 100% 100%;
}
.content-top-left-corner {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/zuoshang1.png) no-repeat;
}
.log-content {
    height: 1000px;
    overflow-y: scroll;
    margin-right: -70px;
    padding: 0 57px;
}
.log-item {
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #7FA2CE;
    font-size: 60px;
    padding-left: 49px;
    padding-right: 74px;
}
.log-item-active {
    background: #15224B;
}
.log-item-zc {
    color: #0FCCFF;
}
.log-item-yc {
    color: #FFBF00;
}
.tab-box {
    width: 4892px;
    height: 159px;
    margin-top: 79px;
    margin-bottom: 67px;
    background: url(../../../assets/images/dataScreen/tab-bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab-pane {
    font-family: PingFang-SC, PingFang-SC;
    /*font-weight: bold;*/
    font-size: 70px;
    color: rgba(255,255,255,0.35);
    line-height: 98px;
    text-align: right;
    font-style: normal;
    cursor: pointer;
}
.tab-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.tab-icon {
    height: 45px;
    margin-bottom: -58px;
}
.active-icon {
    width: 489px;
    height: 45px;
    background: url(../../../assets/images/dataScreen/tabActive.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: -58px;
}
.detection-item {
    display: flex;
    align-items: center;
    margin-left: 179px;
    margin-top: 80px;
    span {
        display: inline-block;
        width: 436px;
    }
}
.detection-icon1 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/kongqiwendu.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon2 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/kongqishidu.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon3 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/fengsu.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon4 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/fengxiang.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon5 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/jiangyuliang.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon6 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/daqiya.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon7 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/eryanghuatan.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon8 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/guangzhao.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon9 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/guanghe.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon10 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/diandaolv.png) no-repeat;
    background-size: 100% 100%;
}
.detection-icon11 {
    width: 163px;
    height: 185px;
    margin-right: 190px;
    background: url(../../../assets/images/dataScreen/yanfen1.png) no-repeat;
    background-size: 100% 100%;
}
.dateMonth {
    width: 210px;
    height: 116px;
    line-height: 116px;
    background: #0C1A40;
    color: #fff;
    text-align: center;
    margin-right: 32px;
    cursor: pointer;
    border: 3px solid rgba(255,255,255,0.06);
}
.dateMonth-active {
    background: #1B447F;
}
.echart-list {
    width: 100%;
    height: calc(2669px - 169px);
    padding: 60px 0 60px 103px;
    display: flex;
    flex-wrap: wrap;
}
.echart-item {
    width: 1715px;
    height: 1123px;
    border-radius: 16px;
    border: 3px solid rgba(255,255,255,0.06);
    padding: 60px 57px 39px 57px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 50px;
    color: #999999;
    font-style: normal;
    margin-right: 92px;
}
.zoushituCls{
    width: 1650px;
    height: 915px;
}
.chartIcon {
    width: 97px;
    height: 20px;
    margin-right: 35px;
    background: url(../../../assets/images/dataScreen/zhixian.png) no-repeat;
    background-size: 100% 100%;
}
.content-top-right-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao2.png) no-repeat;
}
.content-bottom-right-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao3.png) no-repeat;
}
.content-bottom-left-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao4.png) no-repeat;
}
</style>